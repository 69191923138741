import { createAction } from 'typesafe-actions';
import { Action } from 'redux';
import { put, all, select, call, cancelled, takeLatest } from 'redux-saga/effects';
import { addToBasket, removeFromBasket } from 'store/header-middleware/actions';
import { getIsAuthorized, getRegion } from 'common/selectors';
import { api } from 'api';
import { setBasket } from './api';
import { getBasketWithQuantity } from './selectors';

export const ADD_TO_BASKET = createAction('products/ADD_TO_BASKET')<string>();
export const REMOVE_FROM_BASKET = createAction('products/REMOVE_FROM_BASKET')<string>();

export function* rootSaga() {
  yield all([
    takeLatest(ADD_TO_BASKET, function* handle({ payload }) {
      yield put(addToBasket(payload) as Action);
      yield call(sendBasketToServer);
    }),
    takeLatest(REMOVE_FROM_BASKET, function* handle({ payload }) {
      yield put(removeFromBasket(payload) as Action);
      yield call(sendBasketToServer);
    }),
  ]);
}

export function* sendBasketToServer(): Generator {
  const basketArr = (yield select(getBasketWithQuantity)) as ReturnType<typeof getBasketWithQuantity>;
  const isAuthorized = (yield select(getIsAuthorized)) as ReturnType<typeof getIsAuthorized>;
  const regionCode = (yield select(getRegion)) as ReturnType<typeof getRegion>;
  const abortController = (yield call([api, 'abortController'])) as ReturnType<
    (typeof api)['abortController']
  >;
  try {
    if (isAuthorized) {
      yield call(
        setBasket,
        {
          products: basketArr.map(({ code, quantity }) => ({ id: code, quantity })),
          regionCode: regionCode || undefined,
        },
        abortController.token,
      );
    }
  } catch (error) {
    yield call([console, 'error'], error);
  } finally {
    if (yield cancelled()) {
      // type Function from ICancelRequestToken['cancel'] is not useful for call effect
      yield call([abortController as unknown as { cancel: () => void }, 'cancel']);
    }
  }
}
