export const COOKIE_KEY = 'cartInfo';

export const serealizeAEMCookieCollection =
  <T>(serealizeItem: (item: T) => string, splitter = ';') =>
  (itemsCollection: T[] = []): string => {
    const result: string = encodeURIComponent(itemsCollection.map(serealizeItem).join(splitter));
    return result;
  };
export const deserealizeAEMCookieCollection =
  <T>(deserealizeItem: (item: string) => T, splitter = ';') =>
  (cookieValue = ''): T[] => {
    const result: T[] = [];
    if (cookieValue.trim().length > 0) {
      const chunks = decodeURIComponent(cookieValue).split(splitter);
      if (chunks.length > 0) {
        result.push(...chunks.map(deserealizeItem));
      }
    }

    return result;
  };

export function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? matches[1] : undefined;
}

export type Options = { expires?: Date | string; path?: string; domain?: string };

export function setCookie(name: string, value: string, options: Options = {}) {
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  const cookie = [];
  cookie.push(`${name}=${value}`);

  for (let optionKey in options) {
    let optionValue = options[optionKey as keyof Options];
    if (optionValue) {
      cookie.push(`${optionKey}=${optionValue}`);
    }
  }

  document.cookie = cookie.join(';');
}
