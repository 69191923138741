import { ElementType } from 'react';
import { styled } from 'linaria/react';

export const CarouselControl: ElementType = styled.div<{
  direction?: 'left' | 'right';
}>`
  position: absolute;
  top: 50%;
  left: ${({ direction }) => (direction === 'left' ? 'var(--carousel-control-margin, 0)' : 'inherit')};
  right: ${({ direction }) => (direction === 'right' ? 'var(--carousel-control-margin, 0)' : 'inherit')};
  z-index: 2;
  margin-right: 0;
  transform: translateY(-50%) translateX(${({ direction }) => (direction === 'left' ? '-50%' : '50%')});
`;
