/* eslint-disable max-lines */

import React, { useRef, useState, useEffect, useCallback, cloneElement } from 'react';
import { cn } from 'utils/classnames';
import { scrollToPosition } from 'utils/scroll-to-position';
import { FromDesktop } from '../adaptive';
import { CarouselControl } from './styles';
import { ProductsCarouselControl } from 'features/products/components/products-carousel/products-carousel-control';
import type { CarouselVariant } from './types';
import { RootContainer, rootWrapperStyles } from './variants/root';
import { SponsoredContainer, sponsoredWrapperStyles } from './variants/sponsored';

export type CarouselProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  buttonTitle?: {
    left: string;
    right: string;
  };
  canScrollCallBack?: (left?: boolean, right?: boolean) => void;
  controlLeft?: JSX.Element;
  controlRight?: JSX.Element;
  showControlsEveryTime?: boolean;
  controlsClassName?: string;
  scrollStep?: number;
  slWidgetId?: string;
  onChange?: (value: number) => void;
  variant?: CarouselVariant;
  innerContainerClassName?: string;
};

export const Carousel: React.FC<CarouselProps> = ({
  children,
  className,
  controlsClassName,
  buttonTitle = { left: 'Предыдущий слайд', right: 'Следующий слайд' },
  canScrollCallBack,
  controlLeft,
  controlRight,
  showControlsEveryTime = false,
  scrollStep,
  slWidgetId,
  onChange,
  variant = 'root',
  innerContainerClassName,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [inScrollNow, setInScrollNow] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const onControlClick = useCallback(
    (direction: 'left' | 'right') => {
      const wrapper = containerRef.current;

      if (wrapper) {
        const { scrollLeft, offsetWidth, firstElementChild } = wrapper;
        const cardWidth = firstElementChild?.clientWidth ?? 280; // Buttons enabled only from desktop.
        const fitsInViewportCount = Math.floor(offsetWidth / cardWidth);
        let itemMargins = 0;
        if (firstElementChild) {
          const { gap, columnGap } = getComputedStyle(wrapper);
          const { marginLeft, marginRight } = getComputedStyle(firstElementChild);

          itemMargins =
            parseFloat(gap) || parseFloat(columnGap) || parseFloat(marginLeft) + parseFloat(marginRight);
        }

        const evenScrollWidth = (cardWidth + itemMargins) * fitsInViewportCount;
        const step = scrollStep ?? evenScrollWidth;

        setInScrollNow(true);

        let offset = 0;
        if (direction === 'left') {
          offset = scrollLeft - step;
        }
        if (direction === 'right') {
          offset = scrollLeft + step;
        }

        if (onChange) onChange(offset);

        scrollToPosition(
          { x: offset, y: 0 },
          { elementToScroll: wrapper, maxDuration: 650, easingFn: 'easeInOutCubic' },
        ).then(() => setInScrollNow(false));
      }
    },
    [onChange, scrollStep],
  );

  const onScroll = ({ target }: Event) => {
    const { scrollLeft } = target as HTMLDivElement;
    if (!inScrollNow && onChange) onChange(scrollLeft);
    checkCanScroll();
  };

  const checkCanScroll = useCallback(() => {
    const element = containerRef.current;
    if (element) {
      const { scrollLeft, offsetWidth, scrollWidth } = element;
      /**
       * When hide arrow from end of scrolling in px
       */
      const indentation = 100;
      /**
       * Carousel can scroll to left
       * Hide or show left arrow
       */
      if (scrollLeft < indentation) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }
      /**
       * Carousel can scroll to right
       * Hide or show right arrow
       */
      if (scrollWidth - offsetWidth - indentation < scrollLeft) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }
    }
  }, []);

  const setVariantWrapperStyles = useCallback(() => {
    switch (variant) {
      case 'sponsored':
        return sponsoredWrapperStyles;
      case 'root':
      default:
        return rootWrapperStyles;
    }
  }, [variant]);

  const setVariantContainer = () => {
    switch (variant) {
      case 'sponsored':
        return (
          <SponsoredContainer
            className={innerContainerClassName}
            ref={containerRef}
            onScroll={onScroll}
            inScrollNow={inScrollNow}
            data-sl-widget-id={slWidgetId}
            data-sl-external="true"
            data-qa-carousel=""
            data-qa="carousel"
          >
            {children}
          </SponsoredContainer>
        );
      case 'root':
      default:
        return (
          <RootContainer
            className={innerContainerClassName}
            ref={containerRef}
            onScroll={onScroll}
            inScrollNow={inScrollNow}
            data-sl-widget-id={slWidgetId}
            data-sl-external="true"
            data-qa-carousel=""
            data-qa="carousel"
          >
            {children}
          </RootContainer>
        );
    }
  };

  useEffect(() => {
    checkCanScroll();
  }, [checkCanScroll]);

  useEffect(() => {
    if (canScrollCallBack) {
      canScrollCallBack(canScrollLeft, canScrollRight);
    }
  }, [canScrollCallBack, canScrollLeft, canScrollRight]);

  const getControlContent = useCallback(
    (direction: 'left' | 'right' = 'left') => {
      const dataQAAttr = `carousel-control-${direction}`;

      const data = {
        direction,
        onClick: () => onControlClick(direction),
        title: buttonTitle?.[direction],
        'data-qa': dataQAAttr,
      };

      if (controlLeft && direction === 'left') {
        return cloneElement(controlLeft, data);
      }

      if (controlRight && direction === 'right') {
        return cloneElement(controlRight, data);
      }

      return (
        <ProductsCarouselControl direction={data.direction} onClick={data.onClick} data-qa={dataQAAttr} />
      );
    },
    [buttonTitle, controlLeft, controlRight, onControlClick],
  );

  return (
    <div className={cn(className, setVariantWrapperStyles())} {...props}>
      <FromDesktop>
        {(showControlsEveryTime || canScrollLeft) && (
          <CarouselControl direction="left" data-qa-carousel-control-wrapper-left="">
            {getControlContent('left')}
          </CarouselControl>
        )}
      </FromDesktop>
      {setVariantContainer()}
      <FromDesktop>
        {(showControlsEveryTime || canScrollRight) && (
          <CarouselControl direction="right" data-qa-carousel-control-wrapper-right="">
            {getControlContent('right')}
          </CarouselControl>
        )}
      </FromDesktop>
    </div>
  );
};
