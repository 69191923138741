import React from 'react';
import { pricesWrapper } from './styles';
import { ProductPriceToDisplayProps } from './types';
import { ProductAdditionalPrice } from './product-additional-price';
import { ProductPrice } from './product-price';
import { ProductSpecialPrice } from './product-special-price';
import { useProductPrice } from './use-product-price';

export const ProductPriceToDisplay: React.FC<ProductPriceToDisplayProps> = ({
  hasOldPrice,
  price,
  productPriceCategory,
}) => {
  const {
    formattedOldPrice,
    finalCurrency,
    finalPriceFirst,
    finalPriceSecond,
    hasSpecialPrice,
    hasBestPrice,
  } = useProductPrice({ hasOldPrice, price, productPriceCategory });

  return (
    <div className={pricesWrapper}>
      {hasSpecialPrice ? (
        <ProductSpecialPrice
          uom={price.main_uom_rus}
          rawOldPrice={formattedOldPrice}
          currency={finalCurrency}
          priceFirst={finalPriceFirst}
          priceSecond={finalPriceSecond}
          hasBestPrice={hasBestPrice}
          hasOldPrice={hasOldPrice}
          discountPercent={price.discount_percent}
        />
      ) : (
        <ProductPrice
          uom={price.main_uom_rus}
          priceFirst={finalPriceFirst}
          priceSecond={finalPriceSecond}
          currency={finalCurrency}
        />
      )}
      {price.additional_price && price.additional_uom && (
        <ProductAdditionalPrice
          uom={price.additional_uom_rus}
          rawAdditionalPrice={price.additional_price}
          currency={finalCurrency}
        />
      )}
    </div>
  );
};
