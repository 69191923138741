import { css } from 'linaria';
import { createAdaptiveComponent } from './create-adaptive-component';

const fromTablet = css`
  display: none;

  @media (--from-tablet) {
    display: contents;
  }
`;

const atTablet = css`
  display: none;

  @media (--only-tablet) {
    display: contents;
  }
`;

const toTablet = css`
  display: contents;

  @media (--from-desktop) {
    display: none;
  }
`;

export const FromTablet = createAdaptiveComponent({
  displayName: 'FromTablet',
  ssrClassName: fromTablet,
});

export const AtTablet = createAdaptiveComponent({
  displayName: 'AtTablet',
  ssrClassName: atTablet,
});

export const ToTablet = createAdaptiveComponent({
  displayName: 'ToTablet',
  ssrClassName: toTablet,
});
