import React from 'react';
import { Button } from 'fronton-react';
import { controlStyles } from './styles';
import { ChevronLeftIcon, ChevronRightIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: () => void;
  direction?: 'left' | 'right';
};

export const ProductsCarouselControl: React.FC<Props> = ({ direction = 'left', ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      className={controlStyles}
      aria-label={direction === 'right' ? t('ui.carousel.next-slide') : t('ui.carousel.prev-slide')}
      iconOnly
      kind="regular"
      rounded
      invert
      size="l"
      variant="primary"
      {...props}
    >
      {direction === 'right' ? (
        <ChevronRightIcon type="outline" size="l" color="text-primary" />
      ) : (
        <ChevronLeftIcon type="outline" size="l" color="text-primary" />
      )}
    </Button>
  );
};
