import React from 'react';
import { Typography } from 'fronton-react';
import { PackageIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import { styled } from 'linaria/react';
import { isOnlineOnly } from 'features/products/components/product/utils';

type ProductOnlineOrderOnlyProps = {
  className?: string;
  label: 'STD' | 'LOF' | 'BPR' | 'PIM' | 'WIZ';
};

export const ProductOnlineOrderOnly: React.FC<ProductOnlineOrderOnlyProps> = ({ className, label }) => {
  const { t } = useTranslation();
  const isOnlyOrderOnline = isOnlineOnly(label);

  return (
    <OnlineOrderOnly className={className} data-qa="online-order-only">
      {isOnlyOrderOnline ? (
        <>
          <PackageIcon type="outline" size="s" color="secondary-blue-primary" />
          <Typography variant="caption" color="secondary-blue-primary">
            {t('product-card.online-order-only')}
          </Typography>
        </>
      ) : null}
    </OnlineOrderOnly>
  );
};

export const OnlineOrderOnly = styled.div`
  @media (--only-mobile) {
    &:empty {
      display: none;
    }
  }
`;
