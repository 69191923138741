import { css } from 'linaria';
import { styled } from 'linaria/react';

export const container = css`
  display: flex;
  flex-flow: column wrap;
  align-items: baseline;

  @media (--only-mobile) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`;

export const mainPriceWrap = css`
  display: inline-block;
  color: var(--text-primary);
`;

export const mainLargePriceWrap = css`
  font-weight: 700;
  font-size: var(--space-275);
  line-height: 29px;

  @media (--only-mobile) {
    font-size: 18px;
    line-height: var(--space-275);
  }
`;

export const smallPriceWrap = css`
  font-weight: 500;
  font-size: var(--space-175);
  line-height: var(--space-250);
`;

export const primaryPriceFractCurrencyWrap = css`
  display: inline-block;
`;

export const primaryLargePriceFractCurrencyWrap = css`
  font-weight: 700;
  font-size: var(--space-150);
  line-height: var(--space-225);

  @media (--from-tablet) {
    font-size: var(--space-175);
    line-height: 23px;
  }
`;

export const newOldPriceStyle = css`
  color: var(--secondary-red-primary);
`;

export const bestPriceStyle = css`
  color: var(--text-primary);
`;

export const padLeft = css`
  padding-left: var(--space-25);
`;

export const padRight = css`
  padding-right: var(--space-50);
`;

export const specialPriceContainer = css`
  display: flex;
  align-items: baseline;
  margin-right: var(--space-200);

  @media (--only-mobile) {
    width: 100%;
    margin-right: 0;
  }
`;

export const specialOldPriceWrap = css`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

export const oldPriceCrossed = css`
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    top: 50%;
    left: -2px;
    display: block;
    width: 108%;
    height: 1.5px;
    background: var(--secondary-red-primary);
    content: '';
  }
`;

export const oldSmallPriceAndCurrencyWrap = css`
  font-size: var(--space-150) !important;
`;

export const oldCurrencyWrap = css`
  font-size: 12px !important;
  color: var(--text-primary);
  margin-left: var(--space-25);
`;

export const PlateContainer = styled.div`
  height: var(--space-250);
  margin-bottom: var(--space-75);

  & > div {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  span {
    margin: auto var(--space-100);
    font-weight: 700;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  @media (--only-mobile) {
    order: 0;
  }
`;

export const bestPricePlate = css`
  background-color: var(--brand-primary);
  color: var(--secondary-black);
  user-select: none;
  display: flex;
`;

export const discountPercentPlate = css`
  width: max-content;
  overflow: hidden;
  background-color: var(--secondary-red-primary);
  color: var(--text-invert);
  height: var(--space-250);

  > span {
    font-weight: 500;
    font-size: var(--space-175);
    letter-spacing: 0.12px;
    position: relative;
    left: var(--space-25);
    top: calc(-1 * var(--space-60));
    margin-left: var(--space-25);
  }
`;

export const oldPriceWrapper = css`
  display: flex;
  align-items: center;
  gap: var(--space-100);
  margin-bottom: var(--space-75);

  @media (--only-mobile) {
    margin-bottom: var(--space-100);
  }
`;

export const oldPriceWrapperSmall = css`
  align-items: flex-end;
`;
