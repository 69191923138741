import React, {
  memo as ReactMemo,
  ComponentType,
  MemoExoticComponent,
  FC,
  NamedExoticComponent,
  PropsWithChildren,
} from 'react';
import { ComponentWithRenderWarnings, WhyDidYouRenderOptions } from 'types/utils';

type MemoOptions<P> = WhyDidYouRenderOptions & {
  propsAreEqual?: (
    prevProps: Readonly<PropsWithChildren<P>>,
    nextProps: Readonly<PropsWithChildren<P>>,
  ) => boolean;
};

export function memo<P>(Component: FC<P>, options?: MemoOptions<P>): NamedExoticComponent<P>;

export function memo<T extends ComponentType>(
  Component: T,
  options?: MemoOptions<T>,
): MemoExoticComponent<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memo(Component: any, options: MemoOptions<any> = {}): any {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && !__IS_SERVER__) {
    const { propsAreEqual, ...whyDidYouRenderOptions } = options;

    const memoized = ReactMemo(Component, propsAreEqual);
    (
      memoized as ComponentWithRenderWarnings<
        typeof memoized,
        typeof memoized extends React.MemoExoticComponent<infer T> ? T : never
      >
    ).whyDidYouRender = whyDidYouRenderOptions;

    return memoized;
  }

  return ReactMemo(Component, options.propsAreEqual);
}

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && !__IS_SERVER__) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('@welldone-software/why-did-you-render')(React);
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
