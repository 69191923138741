/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/dot-notation */

import { useOnScreen } from './use-on-screen';

type RRTrackingProps = {
  productRef: React.MutableRefObject<HTMLDivElement | null>;
  rrContentId: string | undefined;
};

export const clickRRTracking = (impressionContentId: string) => {
  if (!window['rrApiOnReady']) window['rrApiOnReady'] = [];

  window['rrApiOnReady'].push(function () {
    try {
      window?.rrApi?.impressionContentClicked({
        impressionContentId,
      });
    } catch {
      /* empty */
    }
  });
};

export const viewRRTracking = (impressionContentId: string) => {
  if (!window['rrApiOnReady']) window['rrApiOnReady'] = [];

  window['rrApiOnReady'].push(function () {
    try {
      window?.rrApi?.impressionContentViewed({
        impressionContentId,
      });
    } catch {
      /* empty */
    }
  });
};

export const useRRTracking = ({ productRef, rrContentId }: RRTrackingProps) => {
  const isOnScreen = useOnScreen(productRef, {
    threshold: 0.5,
  });

  const onPointerDown = (e: PointerEvent) => {
    if (!rrContentId) return;

    if (
      e.ctrlKey ||
      e.shiftKey ||
      e.metaKey || // for apple
      (e.button && e.button === 1) // middle/wheel click
    ) {
      clickRRTracking(rrContentId);
      return;
    }

    // disallow right click
    if (e.button === 2) return;

    clickRRTracking(rrContentId);
  };

  if (isOnScreen && rrContentId) viewRRTracking(rrContentId);

  return {
    onPointerDown,
  };
};
