import { createSelector } from 'reselect';
import { createFeatureSelector } from 'features/create-feature-selector';
import { getPendingState } from 'utils/get-pending-state';
import { getLayers } from 'common/selectors/layers';

const featureRootSelector = createFeatureSelector('products');

export const getBasket = createSelector(getLayers, (layers) => layers.basket ?? []);

export const getBasketWithQuantity = createSelector(getLayers, (layers) => layers.basketWithQuantity ?? []);

export const getRatings = createSelector(featureRootSelector, ({ ratings }) => ratings);

export const getRatingsState = createSelector(getRatings, ({ data, isFetching, error }) =>
  getPendingState({
    data,
    pending: isFetching,
    error,
  }),
);

export const getRatingsData = createSelector(getRatings, ({ data }) => data);

export const getRatingsError = createSelector(getRatings, ({ error }) => error);

export const getProductsByIds = createSelector(featureRootSelector, ({ productsByIds }) => productsByIds);

export const getProductsByIdsState = createSelector(getProductsByIds, ({ data, isFetching, error }) =>
  getPendingState({
    data,
    pending: isFetching,
    error,
  }),
);

export const getProductsByIdsData = createSelector(getProductsByIds, ({ data }) => data);
export const getProductsByIdsError = createSelector(getProductsByIds, ({ error }) => error);
