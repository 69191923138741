import { useEffect } from 'react';

export type Params = {
  handle?: Handle;
};

type Handle = 'resize' | 'scroll' | 'both';

export const useVisualViewportApi = (
  callback: (viewport: VisualViewport) => void,
  { handle = 'both' }: Params = {},
) =>
  useEffect(
    () => subscribe((event: VisualViewportEvent) => callback(event.target), handle),
    [callback, handle],
  );

const subscribe = (cb: (event: VisualViewportEvent) => void, handle: Handle): (() => void) => {
  if ('visualViewport' in window) {
    if (['both', 'resize'].includes(handle)) {
      window?.visualViewport?.addEventListener('resize', () => cb);
    }

    if (['both', 'scroll'].includes(handle)) {
      window?.visualViewport?.addEventListener('scroll', () => cb);
    }

    return () => {
      window?.visualViewport?.removeEventListener('resize', () => cb);
      window?.visualViewport?.removeEventListener('scroll', () => cb);
    };
  }

  return () => {};
};
