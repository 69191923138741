import { GlobalRouter } from '@okapi/router-react';
import { combineReducers } from 'redux';
import { createReducer as createRouterReducer } from '@okapi/router-connect';
import { createReducer as createCookieReducer } from 'isomorphic-redux-cookie-middleware';
import { createReducer } from 'typesafe-actions';
import { createReducer as createLayersReducer } from './header-middleware/reducer';
import { Layers } from './header-middleware/types';
import { featuresReducer } from '../features';

export type Env = {
  ORCHESTRATOR_HOST: string;
  ORCHESTRATOR_HOST_KZ?: string;
  ORCHESTRATOR_BACKEND_HOST?: string;
  USE_CREDENTIALS: boolean;
  ENV_TOGGLE: boolean;
  requestID: string;
  API_KEY?: string;
  API_KEY_BACKEND?: string;
};

export const rootReducer = (
  host: string,
  globalRouter: GlobalRouter,
  cookie: string,
  layers: Layers,
  isBot: boolean,
  userAgent: string,
  env: Env,
  uuid?: string,
) =>
  combineReducers({
    carousel: featuresReducer,
    router: createRouterReducer(globalRouter),
    host: createReducer(host),
    cookies: createCookieReducer(cookie),
    layers: createLayersReducer(layers),
    isBot: createReducer(isBot),
    env: createReducer(env),
    userAgent: createReducer(userAgent),
    ...(uuid ? { uuid: createReducer(uuid || '') } : {}),
  });
