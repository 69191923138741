import { REDUX_BASKET_UPDATED } from './actions';
import { PayloadProps, Layers } from './types';

export function createReducer(initialState: Layers) {
  return function reducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initialState,
    action: {
      type: typeof REDUX_BASKET_UPDATED;
      payload: PayloadProps[];
    },
  ) {
    if (action.type === REDUX_BASKET_UPDATED) {
      return {
        ...state,
        basket: action.payload.map((item) => item.code),
        basketWithQuantity: action.payload,
      };
    }
    return state;
  };
}
