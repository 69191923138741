import React from 'react';
import { cx } from 'linaria';
import {
  mainLargePriceWrap,
  mainPriceWrap,
  padLeft,
  padRight,
  primaryLargePriceFractCurrencyWrap,
  primaryPriceFractCurrencyWrap,
} from './product-special-price/styles';

type ProductPriceProps = {
  uom?: string;
  priceFirst?: string;
  priceSecond?: string | null;
  currency: string;
};

export const ProductPrice: React.FC<ProductPriceProps> = ({ uom, priceFirst, priceSecond, currency }) => {
  const priceFirstWithComma = `${priceFirst},`;

  return (
    <div data-qa="product-primary-price">
      <span className={cx(mainPriceWrap, mainLargePriceWrap)} data-qa="primary-price-main">
        {priceSecond ? priceFirstWithComma : priceFirst}
      </span>
      <span
        className={cx(primaryPriceFractCurrencyWrap, primaryLargePriceFractCurrencyWrap, padLeft)}
        data-qa="primary-price-fract"
      >
        {priceSecond}
      </span>
      <span
        className={cx(primaryPriceFractCurrencyWrap, primaryLargePriceFractCurrencyWrap, padRight, padLeft)}
      >
        {`${currency}${uom ? `/${uom}` : ''}`}
      </span>
    </div>
  );
};
