import { Product } from '../product-card/types';

type MeasurementData = Product['measurementData'];

export const isBoxProduct = (m: MeasurementData) => m?.productMeasurementType === 'boxes';
export const isReelProduct = (m: MeasurementData) => m?.productMeasurementType === 'linearMeters';
export const isMeasurableProduct = (m: MeasurementData) => m?.productMeasurementType !== null;

export const getPricePerBox = (p: Product['price']) => {
  if (p?.main_uom === 'CAR') {
    return p.main_price;
  }
  if (p?.additional_uom === 'CAR') {
    return p.additional_price;
  }
  return undefined;
};
