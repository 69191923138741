import React from 'react';
import { ModalContent, ModalHeader, Portal, RegularButton } from 'fronton-react';
import { Modal } from 'fronton-react/modal';
import { css } from 'linaria';
import { Product } from 'common/components/product-card/types';
import { flatListStyles } from './styles';
import { ProductsCarouselItem } from './products-carousel-item';
import { Rating } from '../../types';
import { useTranslation } from 'react-i18next';

export type ProductsBsCarouselProps = React.HTMLAttributes<HTMLElement> & {
  show: boolean;
  onCloseIntent: () => void;
  categoryTitle?: string;
  products: Product[];
  slWidgetId?: string;
  ratings?: Rating[] | null;
  elementBlock: string;
};

export const ProductsBsCarousel: React.FC<ProductsBsCarouselProps> = ({
  show,
  onCloseIntent,
  ratings = [],
  elementBlock,
  categoryTitle,
  slWidgetId,
  products,
}) => {
  const { t } = useTranslation();
  return (
    <Portal>
      <Modal show={show} onClose={onCloseIntent} size="s" variant="curtain">
        <ModalHeader title={categoryTitle} />
        <ModalContent>
          <div
            className={flatListStyles}
            data-sl-widget-id={slWidgetId}
            data-sl-external="true"
            data-qa="products-carousel-flatlist-modal"
          >
            {products.map((product, index) => (
              <ProductsCarouselItem
                key={product.productId}
                index={index}
                product={product}
                ratings={ratings}
                elementBlock={elementBlock}
                data-qa="products-carousel-flatlist-item"
                data-sl-product-slot={index}
              />
            ))}
          </div>
          <div className={footerBlock}>
            <RegularButton onClick={onCloseIntent} size="l" variant="secondary" wide>
              {t('ui.carousel.close')}
            </RegularButton>
          </div>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

const footerBlock = css`
  padding: var(--space-375) var(--space-200);
  margin-top: -1px;
  border-top: 1px solid var(--background-secondary);
`;
