import { css } from 'linaria';
import { createAdaptiveComponent } from './create-adaptive-component';

const atDesktop = css`
  display: none;

  @media (--only-desktop) {
    display: contents;
  }
`;

const fromDesktop = css`
  display: none;

  @media (--from-desktop) {
    display: contents;
  }
`;

export const AtDesktop = createAdaptiveComponent({
  displayName: 'AtDesktop',
  ssrClassName: atDesktop,
});

export const FromDesktop = createAdaptiveComponent({
  displayName: 'FromDesktop',
  ssrClassName: fromDesktop,
});
