import { css } from 'linaria';
import { styled } from 'linaria/react';
import { ElementType } from 'react';
import { whichBrowser } from 'utils/browsers';

export const sponsoredWrapperStyles = css`
  position: relative;
  width: 100%;

  @media (--to-desktop) {
    overflow: hidden;
  }
`;

export const SponsoredContainer: ElementType = styled.div<{ inScrollNow: boolean }>`
  width: 100%;
  display: flex;
  gap: var(--space-150);
  overflow-x: scroll;
  scroll-snap-type: ${({ inScrollNow }) =>
    inScrollNow && whichBrowser() !== 'safari' ? 'none' : 'x proximity'};
  scroll-padding: var(--carousel-scroll-padding, 250px);
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (--to-desktop) {
    scroll-padding: auto;
    scroll-snap-type: none;
  }

  @media (--only-mobile) {
    padding-right: var(--space-200);
  }
`;
