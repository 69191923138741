import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { getRegion, DEFAULT_KZ_REGION } from './location';

export const getEnv = createSelector(
  ({ env }: RootState) => env,
  (env) => env,
);

export const getOrchestratorHost = createSelector(
  getEnv,
  getRegion,
  ({ ORCHESTRATOR_HOST, ORCHESTRATOR_HOST_KZ, ORCHESTRATOR_BACKEND_HOST }, regionCode) => {
    if (__IS_SERVER__) {
      return ORCHESTRATOR_BACKEND_HOST;
    }

    if (regionCode === DEFAULT_KZ_REGION && ORCHESTRATOR_HOST_KZ) {
      return ORCHESTRATOR_HOST_KZ;
    }

    return ORCHESTRATOR_HOST;
  },
);

export const getApiKey = createSelector(getEnv, ({ API_KEY, API_KEY_BACKEND }) =>
  __IS_SERVER__ ? API_KEY_BACKEND : API_KEY,
);
