import React, { useMemo } from 'react';
import { plural } from 'utils/plural';
import { Rating } from 'fronton-react';
import { memo } from '../../hocs';
import { css } from 'linaria';

type ProductReviewsProps = {
  rating: number;
  reviewsNumber: number;
  href: string;
  onClick: () => void;
};

export const ProductReviews = memo<ProductReviewsProps>(function ProductReviews({
  rating,
  reviewsNumber,
  href,
  onClick,
}) {
  const caption = useMemo(() => {
    const wordForm = plural(reviewsNumber, 'отзыв', 'отзыва', 'отзывов');
    return (
      <div>
        <span className={reviewsNumberWrap}>{reviewsNumber}</span>{' '}
        <span className={reviewsCaptionWrap}>{wordForm}</span>
      </div>
    );
  }, [reviewsNumber]);

  return (
    <div>
      <Rating
        hasLink
        value={rating}
        size={'s'}
        withExtraClickZone
        data-qa="product-reviews"
        onClick={onClick}
        href={href}
      >
        {caption}
      </Rating>
    </div>
  );
});

export const reviewsNumberWrap = css`
  @media (--to-largeDesktop) {
    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
`;
export const reviewsCaptionWrap = css`
  display: inline;

  @media (--to-largeDesktop) {
    display: none;
  }
`;
