import React from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import { formatToRu, roundNumber } from 'utils/numbers';
import {
  calculations,
  hidden,
  lengthLabel,
  rollEdge,
  skewEdge,
  spiral,
  svgContainer,
  visible,
  widthLabel,
  wrapper,
} from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  qty: number | null;
  meterPrice: number | null;
  width: number | null;
};

const MAX_EDGE_TRANSLATE_X = 138;

// todo: File was excluded from sonar check bc of duplications in SVG

export const VisualizeLinearMeters = ({ qty, meterPrice, width }: Props) => {
  const { t } = useTranslation();
  if (!qty || !meterPrice || !width) {
    return null;
  }
  const length = qty / width;

  const step = MAX_EDGE_TRANSLATE_X / 7;
  const edgeShift = Math.min(Math.max(step * (length - 1), 0), MAX_EDGE_TRANSLATE_X);

  return (
    <div className={wrapper} data-qa="vm">
      <Typography
        variant="caption"
        data-qa="vm-length"
        className={lengthLabel}
        style={{ transform: `translate3d(${edgeShift / 2}px, 0, 0)` }}
      >
        {formatToRu(roundNumber(length, 1))} {t('measurement-inits.m')}
      </Typography>
      <Typography
        variant="caption"
        data-qa="vm-width"
        className={widthLabel}
        style={{ transform: `translate3d(${edgeShift}px, 0, 0)` }}
      >
        {formatToRu(roundNumber(width, 1))} {t('measurement-inits.m')}
      </Typography>
      <div className={svgContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.06 38.1">
          <path
            d="m16.36,30.55c-.13,0-.27-.03-.39-.08s-.24-.13-.33-.22c-.1-.09-.17-.2-.22-.33-.05-.12-.08-.25-.08-.39s.03-.27.08-.39c.05-.12.13-.23.22-.33l15.53-16.21c.56-.5,1.01-1.12,1.32-1.81s.47-1.43.47-2.19c0-.87-.18-1.73-.51-2.53s-.83-1.53-1.44-2.14c-.62-.61-1.35-1.1-2.15-1.43s-1.67-.5-2.53-.49c-1.9,0-3.74.68-5.2,1.9L4.86,20.02c-.09.1-.21.18-.33.23s-.26.08-.39.08-.27-.03-.39-.08-.24-.13-.33-.23c-.09-.09-.17-.2-.22-.33-.05-.12-.08-.25-.08-.38s.03-.26.08-.38c.05-.12.13-.23.22-.33L19.72,2.45h.07C21.61.86,23.94,0,26.36,0c2.29,0,4.5.89,6.13,2.5,1.63,1.61,2.56,3.81,2.57,6.1,0,1.03-.22,2.05-.64,2.99s-1.03,1.79-1.8,2.48l-15.82,16.35c-.21.2-.16.13-.44.13Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            d="m51.54,14.55h-19.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h19.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            d="m32.2,34.83H9.06c-.59,0,0-.44,0-1,0-.27.11-.52.29-.71.19-.19.44-.29.71-.29h22.14c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            d="m32.2,34.83c-.13,0-.27-.03-.39-.08s-.24-.13-.33-.23c-.1-.09-.17-.2-.22-.33-.05-.12-.08-.25-.08-.39s.03-.27.08-.39.13-.23.22-.33l20.06-20.24c.09-.1.21-.17.33-.23.12-.05.26-.08.39-.08s.27.03.39.08.24.13.33.23c.1.09.17.2.22.33.05.12.08.25.08.39s-.03.27-.08.39c-.05.12-.13.23-.22.33l-20.02,20.27c-.21.19-.48.29-.76.28Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            d="m34.76,38.1l1.14-1.2-.78-.69-1.13,1.2.77.69Zm2.27-2.4l1.09-1.15-.73-.69-1.13,1.2.77.64Zm2.27-2.39l1.13-1.2-.73-.7-1.14,1.2s.74.7.74.7Zm2.26-2.4l1.14-1.2-.74-.69-1.13,1.19s.73.7.73.7Zm2.27-2.36l1.13-1.2-.73-.69-1.11,1.16s.71.73.71.73Zm2.29-2.43l1.13-1.2-.73-.7-1.14,1.2s.74.7.74.7Zm2.27-2.4l1.11-1.17-.73-.69-1.14,1.19.76.67Zm2.26-2.4l1.14-1.2-.74-.69-1.13,1.2s.73.69.73.69Zm2.27-2.39l1.13-1.2-.73-.7-1.13,1.2.73.7Zm2.25-2.38l1.13-1.2-.73-.69-1.14,1.19s.74.7.74.7Zm2.26-2.4l1.14-1.2-.74-.69-1.13,1.2s.73.69.73.69Zm2.27-2.39l1.14-1.21-.74-.7-1.13,1.2s.73.71.73.71Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            className={cx(length > 8 ? hidden : visible, rollEdge)}
            data-qa="line-top"
            d="m231.05,14.55H63.6c-1.13,0-.33-.45-.33-1s-.38-1,.75-1h167.73c1.13,0,1.31.44,1.31,1s-.87,1-2.01,1Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            className={cx(length > 8 ? hidden : visible, rollEdge)}
            data-qa="line-bottom"
            d="m212.02,34.83H44.18c-1.13,0-.81-.44-.81-1s.86-1,2-1h166.11c1.13,0,1.57.44,1.57,1s.1,1-1.03,1Z"
            fill="#333"
            strokeWidth="0"
          />
          <path
            d="m43.4,34.82c-.13,0-.27-.03-.39-.08-.12-.05-.24-.13-.33-.22-.1-.09-.17-.2-.22-.33-.05-.12-.08-.25-.08-.39s.03-.27.08-.39c.05-.12.13-.23.22-.33l19.75-20.13c.09-.1.2-.19.33-.24.12-.06.26-.09.4-.09s.27.02.4.07c.13.05.24.13.34.23.1.1.17.22.22.35s.07.27.06.4c0,.14-.04.27-.1.4s-.15.23-.25.32l-19.67,20.13c-.2.19-.47.3-.75.3h-.01Z"
            fill="#333"
            strokeWidth="0"
          />
          <g data-qa="spiral" className={spiral} style={{ transform: `rotate(${-30 * qty}deg)` }}>
            <path
              d="m9.06,33.81c4.45,0,8.06-3.61,8.06-8.06s-3.61-8.06-8.06-8.06S1,21.3,1,25.75s3.61,8.06,8.06,8.06Z"
              fill="none"
              stroke="#333"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              d="m6.29,18.18c3.19-1.17,6.41.37,7.69,3.88.49,1.35.43,2.84-.18,4.14-.61,1.3-1.7,2.31-3.05,2.8-1.08.39-2.27.34-3.31-.14-1.04-.48-1.85-1.36-2.25-2.44-.31-.86-.27-1.82.12-2.65s1.09-1.48,1.95-1.8c.69-.25,1.45-.21,2.12.1s1.18.87,1.44,1.56"
              fill="none"
              stroke="#333"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
          <g className={cx(length > 8 ? visible : hidden, rollEdge)} data-qa="dashed-bottom">
            <path
              d="m121.99,34.86h-1.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h1.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m128.19,34.86h-1.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h1.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m134.41,34.86h-1.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h1.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m115.22,34.86H43.97c-1.13,0-.81-.45-.81-1s0-1,1.16-1h70.9c1.13,0,1.56.44,1.56,1s-.43,1-1.56,1Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m139.52,32.86h72.25c1.13,0,.81.44.81,1s.49,1-.65,1h-72.41c-1.13,0-1.56-.45-1.56-1s.45-1,1.56-1Z"
              fill="#333"
              strokeWidth="0"
            />
          </g>
          <g className={cx(length > 8 ? visible : hidden, rollEdge)} data-qa="dashed-top">
            <path
              d="m144.38,14.55h-1.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h1.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m150.58,14.55h-1.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h1.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m156.8,14.55h-1.64c-.27,0-.52-.11-.71-.29-.19-.19-.29-.44-.29-.71s.11-.52.29-.71c.19-.19.44-.29.71-.29h1.64c.27,0,.52.11.71.29.19.19.29.44.29.71s-.11.52-.29.71c-.19.19-.44.29-.71.29Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m137.61,14.55H63.43c-1.13,0-.81-.45-.81-1s0-1,1.16-1h73.84c1.13,0,1.56.44,1.56,1s-.43,1-1.56,1Z"
              fill="#333"
              strokeWidth="0"
            />
            <path
              d="m161.62,12.55h70.17c1.13,0,.81.44.81,1s-.86,1-2,1h-68.98c-1.13,0-1.56-.45-1.56-1s.45-1,1.56-1Z"
              fill="#333"
              strokeWidth="0"
            />
          </g>
        </svg>
        <div
          data-qa="skewEdge"
          style={{ transform: `translate3d(${edgeShift}px, 0, 0)` }}
          className={cx(rollEdge, skewEdge)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.77 22.29">
            <path
              d="m1.03,22.28c-.2,0-.4-.05-.57-.16-.17-.11-.3-.27-.38-.46s-.1-.39-.05-.59c.04-.2.15-.38.29-.52L20.03.31c.09-.1.21-.17.33-.23.12-.05.26-.08.39-.08s.27.03.39.08.24.13.33.23c.1.09.17.2.22.33.05.12.08.25.08.39s-.03.27-.08.39-.13.23-.22.33L1.76,21.99c-.19.19-.46.3-.73.3h0Z"
              fill="#333"
              strokeWidth="0"
            />
          </svg>
        </div>
      </div>
      <div className={calculations}>
        <Typography variant="caption" data-qa="vmc-left">
          = {formatToRu(roundNumber(qty, 1))} {t('measurement-inits.m2')}
        </Typography>
        <Typography variant="caption_accent" data-qa="vmc-right">
          = {formatToRu(roundNumber(qty * meterPrice, 2))} {t('measurement-inits.currency')}
        </Typography>
      </div>
    </div>
  );
};
