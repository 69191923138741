import { css } from 'linaria';

export const cartButton = css`
  height: 40px;
  margin-top: auto;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700 !important;
`;

export const qtyInput = css`
  min-width: 160px;
  width: 100%;
`;

export const imageBaseWrapper = css`
  position: relative;
  flex-shrink: 0;
`;

export const cartControlWrapper = css`
  @media (--from-desktop) {
    width: 160px !important;
  }

  @media (--to-desktop) {
    width: 100% !important;
  }
`;

export const productBaseWrapper = css`
  display: flex;
  padding: var(--space-250);

  &.list {
    flex-direction: row;
    min-height: 200px;

    .${imageBaseWrapper} {
      width: 160px;
      height: 160px;
    }
  }

  &.large-card {
    flex-direction: column;
    height: 100%;

    .${imageBaseWrapper} {
      width: 180px;
      height: 180px;
    }

    @media (--only-largeDesktop) {
      .${imageBaseWrapper} {
        width: 240px;
        height: 240px;
      }
    }

    @media (--only-tablet) {
      padding: var(--space-200);
    }
  }

  @media (--only-mobile) {
    &,
    &.large-card,
    &.list {
      flex-direction: column;
      height: 100%;
      padding: var(--space-200);
      box-shadow: rgb(240 241 242) 0.5px 0 inset;

      .${imageBaseWrapper} {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const offerBaseWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &.list {
    flex-shrink: 0;

    .${qtyInput} {
      width: 100%;
      max-width: none;
      margin-top: auto;
    }

    @media (--from-tablet) {
      width: 128px;
      padding: var(--space-250) 0 var(--space-125);
      margin-left: var(--space-300);
    }

    @media (--from-desktop) {
      width: 130px;
      margin-left: var(--space-500);
    }

    @media (--from-largeDesktop) {
      min-width: 215px;
      margin-right: var(--space-375);
      margin-left: var(--space-875);
    }
  }

  &.large-card {
    margin-top: auto;

    @media (--from-desktop) {
      .${cartButton} {
        width: 160px;
      }
    }

    @media (--only-tablet) {
      .${cartButton} {
        width: 100%;
      }
    }
  }

  @media (--only-mobile) {
    margin-top: auto;
  }
`;

export const contentBaseWrapper = css`
  width: 100%;

  &.large-card {
    margin: var(--space-125) 0 0;
  }

  &.list {
    margin-left: var(--space-250);

    @media (--only-tablet) {
      margin-left: var(--space-200);
    }
  }

  @media (--only-mobile) {
    &,
    &.large-card,
    &.list {
      margin: var(--space-125) 0 0;
    }
  }
`;
