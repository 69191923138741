import React from 'react';
import { css } from 'linaria';
import { cn } from 'utils/classnames';
import { BaseButton, Typography } from 'fronton-react';

type ProductNameProps = {
  href: string;
  textClassName?: string;
} & React.AnchorHTMLAttributes<HTMLButtonElement>;

export const ProductName: React.FC<ProductNameProps> = ({
  href,
  className,
  children,
  onClick,
  textClassName,
}) => {
  return (
    <BaseButton onClick={onClick} href={href} className={cn(productName, className)} data-qa="product-name">
      <Typography variant="s" size="body_short" color="text-primary" className={textClassName}>
        {children}
      </Typography>
    </BaseButton>
  );
};

const productName = css`
  text-decoration: none;
`;
