import React from 'react';
import { discountPercentPlate } from './styles';
import { Percent } from 'common/icons/percent';

type ProductDiscountPercentProps = {
  percent?: number;
};

export const ProductDiscountPercent: React.FC<ProductDiscountPercentProps> = ({ percent }) => {
  const percentView = percent ? (percent * 100).toFixed() : '';

  return (
    <div data-qa="discountPercentPlate" className={discountPercentPlate}>
      <span>&minus;{percentView}</span>
      <Percent />
    </div>
  );
};
