import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { range } from 'utils/range';

export const DEFAULT_RU_REGION = 'moscow';
export const DEFAULT_KZ_REGION = 'kazakhstan';

export const RU_DOMAINS = [
  'adeo.com',
  'leroymerlin.ru',
  'localhost',
  'lmru.tech',
  'lemonlid.ru',
  'lemanapro.ru',
];
export const KZ_DOMAINS = ['lemanapro.kz', 'lmkz.tech', 'leroymerlin.kz'];

const isDomainValid = (domain: string, domains: string[]) =>
  domains.some((matchingDomain) => domain === matchingDomain);

export const getHost = ({ host }: RootState) => host;

export const getHostname = createSelector(getHost, (host) => host?.replace(/:[0-9]+/, ''));

export const getPathname = createSelector(
  ({ router }: RootState) => router.path,
  (s) => s,
);

export const getDomain = createSelector(getHostname, (hostname = '') => {
  let result = '';

  const localhost = 'localhost';
  const separator = '.';
  const start = hostname.indexOf(separator);

  const isIpAddress =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g.test(
      hostname,
    );

  if (isIpAddress) {
    return hostname;
  }

  if (hostname.endsWith(localhost)) {
    const splitted = hostname.split('.');

    return splitted[splitted.length - 1];
  }

  if (start !== -1) {
    const split = hostname.split(separator);

    result = hostname
      .split(separator)
      .slice(split.length - 2)
      .join(separator);
  }

  return result;
});

export const getRegion = createSelector(getHostname, getDomain, (hostname, domain) => {
  const allFsStands = range(20).map((index) => `fs${index}`);
  const definitelyNotARegion = [
    ...allFsStands,
    'aem',
    'arulm',
    'author',
    'dispatcher',
    'internal',
    'isomorphic-shared-stage',
    'lemanapro',
    'lemonlid',
    'leroymerlin',
    'localhost',
    'mf',
    'mf-capella-a',
    'mf-capella-a-stage',
    'mf-taurus-a',
    'publish',
    'triffle',
    'ux',
    'yandex',
  ];

  const isRuDomain = isDomainValid(domain, RU_DOMAINS);
  const isKZDomain = isDomainValid(domain, KZ_DOMAINS);

  if (isRuDomain || isKZDomain) {
    const [maybeRegion] = hostname.split('.');

    if (!definitelyNotARegion.some((prefix) => maybeRegion.startsWith(prefix))) {
      return maybeRegion;
    }
  }

  if (isRuDomain) {
    return DEFAULT_RU_REGION;
  }

  if (isKZDomain) {
    return DEFAULT_KZ_REGION;
  }

  return '';
});
