import { Dispatch, MiddlewareAPI } from 'redux';
import {
  ReducerAction,
  REDUX_BASKET_ADD,
  REDUX_BASKET_CERTAIN_QUANTITY_ADD,
  REDUX_BASKET_REMOVE,
  REDUX_BASKET_REMOVE_QUANTITY,
  REDUX_BASKET_UPDATED,
} from './actions';

export const createMiddleware = () => (store: MiddlewareAPI) => {
  if (!__IS_SERVER__) {
    document.body.addEventListener('basket:update', (e) => {
      store.dispatch({
        type: REDUX_BASKET_UPDATED,
        payload: (e as CustomEvent).detail,
      });
    });
  }

  return (next: Dispatch) => (action: ReducerAction) => {
    switch (action.type) {
      case REDUX_BASKET_ADD:
        document.body.dispatchEvent(
          new CustomEvent('basket:add', { detail: { id: action.payload, quantity: 1 } }),
        );
        break;
      case REDUX_BASKET_CERTAIN_QUANTITY_ADD:
        document.body.dispatchEvent(
          new CustomEvent('basket:add', {
            detail: { id: action.payload.code, quantity: action.payload.quantity },
          }),
        );
        break;
      case REDUX_BASKET_REMOVE:
        document.body.dispatchEvent(
          new CustomEvent('basket:delete:element', { detail: { id: action.payload } }),
        );
        break;
      case REDUX_BASKET_REMOVE_QUANTITY:
        document.body.dispatchEvent(
          new CustomEvent('basket:remove', {
            detail: { id: action.payload.code, quantity: action.payload.quantity },
          }),
        );
        break;
      default:
        return next(action);
    }
  };
};
