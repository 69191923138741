/**
 * Выбирает форму множественного числа в зависимости от переданного числа
 *
 * @param number - число, на основании которого будет выбрана форма
 * @param single - форма единственного числа
 * @param few - форма при величине от 2 до 5
 * @param many - форма множественного числа
 */
export function plural(number: number, single: string, few: string, many: string) {
  let n = Math.abs(number) % 100;

  if (n >= 5 && n <= 20) {
    return many;
  }

  n %= 10;

  if (n === 1) {
    return single;
  }

  if (n >= 2 && n <= 4) {
    return few;
  }

  return many;
}
