import { css } from 'linaria';

export const wrapper = css`
  position: relative;
  padding: var(--space-325) var(--space-200) var(--space-150);
  overflow: hidden;
`;

export const calculations = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space-50);
`;

export const spiral = css`
  transform-origin: 9.1px 25.7px;
  transition: 0.3s ease-out;
`;

export const rollEdge = css`
  transition: 0.3s;
`;

export const skewEdge = css`
  position: absolute;
  top: 12.6px;
  left: 74px;

  svg {
    position: relative;
    z-index: 9002;
    height: 21.79px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 10px;
    z-index: 9001;
    width: 300px;
    height: 25px;
    background-color: var(--accent-light);
    transform: skewX(-42deg);
  }
`;

export const visible = css`
  opacity: 1;
`;
export const hidden = css`
  opacity: 0;
`;

export const svgContainer = css`
  position: relative;
  padding-right: 23px;
`;

export const widthLabel = css`
  position: absolute;
  top: 43px;
  left: 108px;
  z-index: 9002;
  transition: 0.3s;
`;
export const lengthLabel = css`
  position: absolute;
  top: 19px;
  left: 84px;
  text-align: center;
  transition: 0.3s;
`;
