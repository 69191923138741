import { css } from 'linaria';
import { cn } from 'utils/classnames';
import { offerBaseWrapper, contentBaseWrapper, productBaseWrapper, imageBaseWrapper } from './common-styles';
import { styled } from 'linaria/react';

export const imageWrapper = imageBaseWrapper;

export const productLabel = css`
  position: absolute;
  top: -8px;
  left: -8px;

  @media (--only-mobile) {
    top: 0;
    left: 0;
  }
`;

export const productId = css`
  font-size: 12px;
  color: var(--text-minor);
`;

export const productName = css`
  border: none !important;
  justify-content: left !important;
`;

export const productNameText = css`
  display: inherit;
  -webkit-box-orient: vertical;
`;

export const productNameTextInWidget = css`
  display: block !important;
  display: -webkit-box !important;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  max-height: var(--space-900);
`;

export const productWrapper = productBaseWrapper;

export const offerWrapper = offerBaseWrapper;

export const offerOldPriceWrapper = css`
  padding-top: 0 !important;
`;

export const pricesWrapper = css`
  margin-bottom: var(--space-150);
`;

const contentWrapperEnhanced = css`
  display: flex;
  flex-direction: column;
  word-break: break-word;

  @media (--only-mobile) {
    &,
    &.large-card,
    &.list {
      .${productId} {
        display: none;
      }

      .${productNameText} {
        -webkit-line-clamp: 6;
      }
    }
  }
`;

export const onlineOrderOnly = css`
  display: flex;
  gap: var(--space-50);
  height: var(--space-200);
  margin-top: var(--space-150);
`;

export const secondaryPrice = css`
  position: relative;
  margin-top: var(--space-75);

  @media (--only-mobile) {
    margin-top: var(--space-50);
  }
`;
export const ImgWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const contentWrapper = cn(contentBaseWrapper, contentWrapperEnhanced);
