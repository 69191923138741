import React from 'react';

export const Percent = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" transform="translate(0 0.000488281)" fill="#B82825" />
    <path
      d="M6.65347 10.1528C5.045 10.1528 4.09082 9.19716 4.09082 7.67362V7.47971C4.09082 5.95617 5.045 5.00049 6.65347 5.00049C8.26194 5.00049 9.20249 5.95617 9.20249 7.47971V7.67362C9.20249 9.19716 8.26194 10.1528 6.65347 10.1528ZM6.65347 8.7955C7.13056 8.7955 7.51223 8.47694 7.51223 7.67362V7.47971C7.51223 6.67639 7.13056 6.35783 6.65347 6.35783C6.16275 6.35783 5.78108 6.67639 5.78108 7.47971V7.67362C5.78108 8.47694 6.16275 8.7955 6.65347 8.7955ZM13.36 15.0005C11.7515 15.0005 10.7973 14.0448 10.7973 12.5213V12.3274C10.7973 10.8038 11.7515 9.84813 13.36 9.84813C14.9685 9.84813 15.909 10.8038 15.909 12.3274V12.5213C15.909 14.0448 14.9685 15.0005 13.36 15.0005ZM13.36 13.6431C13.8371 13.6431 14.2187 13.3246 14.2187 12.5213V12.3274C14.2187 11.524 13.8371 11.2055 13.36 11.2055C12.8693 11.2055 12.4876 11.524 12.4876 12.3274V12.5213C12.4876 13.3246 12.8693 13.6431 13.36 13.6431ZM6.29906 14.8481L12.0105 5.15284H13.7144L8.00295 14.8481H6.29906Z"
      fill="white"
    />
  </svg>
);
