import { createSelector } from 'reselect';
import { createFeatureSelector } from 'features/create-feature-selector';
import { BasketItem } from '../types';

const featureRootSelector = createFeatureSelector('basket');

export const getBasket = createSelector(featureRootSelector, (b) => b ?? []);

export const getCartItem = (productId: string) =>
  createSelector(getBasket, (basket: BasketItem[]) => basket?.find(({ code }) => code === productId));
