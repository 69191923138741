export interface GetPendingStateParams {
  pending: boolean;
  error: boolean | unknown | null | undefined;
  data: boolean | unknown | null | undefined;
}

export type GetPendingStateResult = {
  loadingState: 'none' | 'loading' | 'loaded';
  fullState: 'none' | 'loading' | 'fulfilled' | 'error';
};

export function getPendingState({ data, error, pending }: GetPendingStateParams): GetPendingStateResult {
  if (pending) {
    return { loadingState: 'loading', fullState: 'loading' };
  }

  if (error) {
    return { loadingState: 'loaded', fullState: 'error' };
  }

  if (data) {
    return { loadingState: 'loaded', fullState: 'fulfilled' };
  }

  return {
    fullState: 'none',
    loadingState: 'none',
  };
}
