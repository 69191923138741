import { useEffect, useState } from 'react';

export const intersectingFnCurry =
  (callback: () => void): IntersectionObserverCallback =>
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback();
        observer.unobserve(entry.target);
      }
    });
  };

export const useOnScreen = (
  ref: React.MutableRefObject<Element | null>,
  options?: IntersectionObserverInit,
) => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const isElement = !__IS_SERVER__ ? ref.current instanceof window.HTMLElement : false;

  useEffect(() => {
    const interFn = intersectingFnCurry(() => {
      setIsOnScreen(true);
    });
    const observer = new IntersectionObserver(interFn, options);

    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [isElement, options, ref]);

  return isOnScreen;
};
