import React from 'react';
import { Typography } from 'fronton-react';
import { formatToRu, roundNumber } from 'utils/numbers';
import { wrapper } from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  qty: number | undefined;
  boxPrice?: number;
  boxVolume: number | null;
};

export const VisualizeBoxes = ({ qty, boxPrice, boxVolume }: Props) => {
  const { t } = useTranslation();
  if (!qty || !boxPrice || !boxVolume) {
    return null;
  }
  return (
    <div className={wrapper} data-qa="vb">
      <Typography variant="caption" data-qa="vb-left">
        {qty} {t('measurement-inits.boxes')} = {formatToRu(roundNumber(boxVolume * qty, 1))}{' '}
        {t('measurement-inits.m2')}
      </Typography>
      <Typography variant="caption_accent" data-qa="vb-right">
        = {formatToRu(roundNumber(qty * boxPrice, 2))} {t('measurement-inits.currency')}
      </Typography>
    </div>
  );
};
