import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { cn } from 'utils/classnames';
import { useFocusOnContext } from 'fronton-react/_internal/hooks';
import { popperModifiers, tooltipPlacement } from 'fronton-react/utils';
import { TooltipProps } from './types';
import { arrowStyles, disabled, inModal, tooltipStyles, wrapper } from './styles';
import { VisualizeBoxes } from './visualisation/boxes';
import { VisualizeLinearMeters } from './visualisation/linear-meters';
import { getPricePerBox, isBoxProduct, isReelProduct } from './utils';
import { useTranslation } from 'react-i18next';

export const MeasurementHintTooltip: React.FC<TooltipProps> = ({
  className,
  placement = 'top',
  open = false,
  children,
  qty = 1,
  price,
  measurementData,
}) => {
  const childElement = useRef(null);
  const tooltip = useRef(null);
  const arrow = useRef(null);
  const [isElementsSetup, setIsElementsSetup] = useState(false);
  const focusOnContext = useFocusOnContext();

  const isBoxesMeasure = isBoxProduct(measurementData);
  const isLinearMetersMeasure = isReelProduct(measurementData);

  const { t } = useTranslation();

  const { styles, attributes, update } = usePopper(childElement.current, tooltip.current, {
    placement,
    strategy: focusOnContext ? 'fixed' : 'absolute',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrow.current,
          padding: 14,
        },
      },
      ...popperModifiers({ fallback: tooltipPlacement, listeners: open }),
    ],
  });

  useEffect(() => {
    update?.();
  }, [update, children]);

  useEffect(() => {
    if (!isElementsSetup) {
      setIsElementsSetup(true);
    }
  }, [isElementsSetup]);

  return (
    <div className={cn(wrapper, className)}>
      {React.cloneElement(children as React.ReactElement, { ref: childElement })}
      <div
        className={cn(tooltipStyles, { [disabled]: !open, [inModal]: focusOnContext })}
        ref={tooltip}
        style={styles.popper}
        role="tooltip"
        aria-label={t('ui.aria-label.measurement-hint')}
        {...attributes.popper}
      >
        {isBoxesMeasure && (
          <VisualizeBoxes qty={qty} boxPrice={getPricePerBox(price)} boxVolume={measurementData.m2PerBox} />
        )}
        {isLinearMetersMeasure && (
          <VisualizeLinearMeters qty={qty} meterPrice={price.main_price} width={measurementData.width} />
        )}
        <div className={arrowStyles} ref={arrow} style={styles.arrow} />
      </div>
    </div>
  );
};
