import type { RootState } from 'typesafe-actions';
import type { ProductOwnProps } from './product.types';
import { BasketFlowActions, getBasket } from 'features/basket';
import type { Dispatch } from 'redux';

export const mapStateToProps = (state: RootState, ownProps: ProductOwnProps) => {
  const basket = getBasket(state);
  const { product } = ownProps;
  const cartEntity = basket.find((e: { code: string }) => e.code === product.productId);
  const inBasket = !!cartEntity;
  const qty = cartEntity?.quantity;
  return { inBasket, qty };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  addToBasket: (code: string, quantity: number) => {
    dispatch(BasketFlowActions.UPDATE_ITEM({ quantity, code }));
  },
  updateCartItem: (code: string, quantity: number) =>
    dispatch(BasketFlowActions.UPDATE_ITEM({ quantity, code })),
  syncCartWithCookie: () => dispatch(BasketFlowActions.SYNC_CART_WITH_COOKIE()),
  removeFromBasket: (code: string) => {
    dispatch(BasketFlowActions.RESET_ITEM({ code }));
  },
});
