import React, { memo, useMemo } from 'react';
import { styled } from 'linaria/react';
import { DelayedContent } from '../delayed-content';

export type SkeletonProps = {
  width?: string;
  height?: string;
  blink?: boolean;
  position?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Skeleton: React.FC<SkeletonProps> = memo(
  ({ className = '', width, height, style, blink = true, ...rest }) => {
    const styles: React.CSSProperties = useMemo(
      () => ({
        ...style,
        ...(width && { width }),
        ...(height && { height }),
      }),
      [width, height, style],
    );

    return (
      <DelayedContent>
        <SkeletonItem className={className} style={styles} blink={blink} data-qa="skeleton" {...rest} />
      </DelayedContent>
    );
  },
);

export const SkeletonItem = styled.div<
  React.HTMLAttributes<HTMLDivElement> & {
    blink: SkeletonProps['blink'];
    position: SkeletonProps['position'];
  }
>`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--control-primary_opacity-4);
  background-image: linear-gradient(
    90deg,
    var(--control-primary_opacity-4),
    var(--background-primary) 300px,
    var(--control-primary_opacity-4)
  );
  background-repeat: no-repeat;
  background-position-x: -600px;
  background-size: 600px 100%;
  animation: 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0s infinite normal none running gradient-flow;
  animation-play-state: ${({ blink }) => (blink ? 'running' : 'paused')};
  position: ${({ position }) => (position ? position : 'static')};
  will-change: background-position;
  contain: strict;

  @keyframes gradient-flow {
    0% {
      background-position-x: -612px;
    }

    100% {
      background-position-x: 434px;
    }
  }
`;
