import React from 'react';
import { registerWidget } from '@okapi/core';
import { RootState } from 'typesafe-actions';
import { getGlobalRouter } from '@okapi/router-react';
import { CarouselEntryWidget } from 'entries/common/widgets/carousel';
import { createStore } from 'store';
import { getLayers } from 'store/header-middleware/utils/get-layers';
import { ProductsCarouselProps } from 'features/products/components/products-carousel';

registerWidget<RootState, ProductsCarouselProps>(`${__NAME__}/root`, (state) => {
  const router = getGlobalRouter();

  const { store } = createStore({
    router,
    preloadedState: state,
    host: window.location.host,
    cookie: document.cookie,
    layers: getLayers(document.cookie),
    isBot: false,
    userAgent: window.navigator.userAgent,
    env: {
      ORCHESTRATOR_HOST: '',
      ORCHESTRATOR_HOST_KZ: '',
      ORCHESTRATOR_BACKEND_HOST: '',
      requestID: '',
      USE_CREDENTIALS: false,
      ENV_TOGGLE: false,
    },
  });

  return (props) => <CarouselEntryWidget store={store} {...props} />;
});
