import { put, select, all, takeEvery, call, cancelled, takeLatest } from 'redux-saga/effects';
import { createReducer } from 'typesafe-actions';
import { getDomain, getIsAuthorized, getRegion } from 'common/selectors';
import { api } from 'api';
import log from 'loglevel';
import {
  deserealizeAEMCookieCollection,
  getCookie,
  serealizeAEMCookieCollection,
  setCookie,
} from 'utils/cartinfo-cookie';
import { getProductQuantity } from '../utils/get-product-quantity';
import { getBasket, getCartItem } from './selectors';
import { BASKET_SET, BASKET_UPDATE_ITEM, BASKET_RESET, BasketFlowActions } from './actions';
import { setBasket } from '../../products/ducks/api';
import { BasketItem } from '../types';

const COOKIE_KEY = 'cartInfo';

const serealizeBasket = serealizeAEMCookieCollection((item: BasketItem) => `${item.code}:${item.quantity}`);
const deserealizeBasket = deserealizeAEMCookieCollection<BasketItem>((item: string) => {
  const [code, qty] = item.split(':');
  return {
    code,
    quantity: parseFloat(qty),
  };
});

export const basket = createReducer<BasketItem[] | null>([])
  .handleAction(BASKET_SET, (state, { payload }) => payload)
  .handleAction(BASKET_UPDATE_ITEM, (state, { payload }) => {
    const res = state instanceof Array ? [...state] : [];

    const itemIdx = res.findIndex(({ code }) => code === payload.code);
    if (itemIdx >= 0) {
      res.splice(itemIdx, 1);
    }

    if (payload.quantity > 0) {
      res.push(payload);
    }

    return res;
  });

export function* updateBasketItem() {
  const basketArr: BasketItem[] = yield select(getBasket);
  const domain: string = yield select(getDomain);
  setCookie(COOKIE_KEY, serealizeBasket(basketArr), {
    path: '/',
    domain,
    expires: new Date(new Date().getFullYear() + 1, 12, 31),
  });
  document.body.dispatchEvent(
    new CustomEvent('basket:update', {
      cancelable: true,
      bubbles: true,
      composed: true,
      detail: basketArr,
    }),
  );
  /**
   * API call should be last because of current basket web component implementation;
   * `change` handler fires twice & dispatches 2 basket flow actions for basket update
   */
  const isAuthorized = (yield select(getIsAuthorized)) as ReturnType<typeof getIsAuthorized>;
  const regionCode = (yield select(getRegion)) as ReturnType<typeof getRegion>;
  const abortController = api.abortController();
  try {
    if (isAuthorized) {
      yield call(
        setBasket,
        {
          products: basketArr.map(({ code, quantity }) => ({ id: code, quantity })),
          regionCode: regionCode || undefined,
        },
        abortController.token,
      );
    }
  } catch (error) {
    yield call([log, 'error'], error);
  } finally {
    if ((yield cancelled()) as boolean) {
      abortController.cancel();
    }
  }
}

export function* rootSaga() {
  yield all([
    // takeLatest because we should cancel previous API call
    takeLatest(BASKET_UPDATE_ITEM, () => updateBasketItem()),
    takeEvery(BasketFlowActions.UPDATE_ITEM, handleBasketItemFlow),
    takeEvery(BasketFlowActions.RESET_ITEM, handleBasketResetItemFlow),
    takeEvery(BasketFlowActions.SYNC_ITEM_WITH_COOKIE, handleBasketSyncItemWithCookieFlow),
    takeEvery(BasketFlowActions.SYNC_CART_WITH_COOKIE, handleBasketSyncWithCookies),
  ]);
}

// handleBasketItemFlow
export function* handleBasketItemFlow({ payload }: { payload: BasketFlowActions.UpdateItemPayload }) {
  const { code, quantity } = payload;
  yield put(BASKET_RESET());
  yield put(BASKET_UPDATE_ITEM({ code, quantity }));
}

export function* handleBasketResetItemFlow({ payload }: { payload: BasketFlowActions.ResetItemPayload }) {
  const { code } = payload;
  const item: BasketItem = yield call(selectCartItem, code);
  const currentQty = item?.quantity ?? 0;
  yield put(BasketFlowActions.UPDATE_ITEM({ code, quantity: -currentQty }));
}

export function* handleBasketSyncWithCookies() {
  const basketFromCookies: BasketItem[] = yield deserealizeBasket(getCookie(COOKIE_KEY) || '');
  yield put(BASKET_SET(basketFromCookies));
}

export function* handleBasketSyncItemWithCookieFlow({
  payload,
}: {
  payload: BasketFlowActions.SyncItemWithCookiePayload;
}) {
  const { code } = payload;
  const item: BasketItem = yield call(selectCartItem, code);
  const currentQty = item?.quantity ?? 0;
  const quantityInBasketCookie: number = yield call(selectProductQuantityInBasketCookie, code);
  const flowQty = quantityInBasketCookie - currentQty;
  if (flowQty !== 0) {
    yield put(
      BasketFlowActions.UPDATE_ITEM({
        code,
        quantity: flowQty,
        ...(typeof payload?.isNeedGA === 'boolean' && { isNeedGA: payload.isNeedGA }),
      }),
    );
  }
}

export function* selectCartItem(productId: string) {
  const item: BasketItem = yield select(getCartItem(productId));
  return item;
}

export function* selectProductQuantityInBasketCookie(productId: string) {
  const basketFromCookies: BasketItem[] = yield deserealizeBasket(getCookie(COOKIE_KEY) || '');
  const result: number = yield getProductQuantity(basketFromCookies, productId);
  return result;
}
