import React, { useMemo } from 'react';
import { Typography } from 'fronton-react';
import { padLeft } from './product-special-price/styles';
import { secondaryPrice } from './styles';

type ProductAdditionalPriceProps = {
  rawAdditionalPrice: number;
  currency: string;
  uom?: string;
};

export const ProductAdditionalPrice: React.FC<ProductAdditionalPriceProps> = ({
  rawAdditionalPrice,
  currency,
  uom,
}) => {
  const additionalPrice = useMemo(() => {
    return rawAdditionalPrice.toFixed(2).replace('.', ',').replace(',00', '');
  }, [rawAdditionalPrice]);

  return (
    <div data-qa="product-secondary-price" className={secondaryPrice}>
      <Typography variant="caption" color="text-minor">
        {additionalPrice}
      </Typography>
      <Typography variant="caption" color="text-minor" className={padLeft}>
        {`${currency}${uom ? `/${uom}` : ''}`}
      </Typography>
    </div>
  );
};
