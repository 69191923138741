import React from 'react';
import { bestPricePlate, PlateContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { BestPriceIcon } from 'common/icons/best-price-icon';

export const ProductBestPriceNameplate = () => {
  const { t } = useTranslation();
  return (
    <PlateContainer className={bestPricePlate} data-qa="product-best-price-nameplate">
      <BestPriceIcon />
      <span>{t('product-card.best-price')}</span>
    </PlateContainer>
  );
};
