import React, { useCallback, useState, useRef } from 'react';
import { IconButton, MinusIcon, PlusIcon } from 'fronton-react';
import { cx } from 'linaria';
import { useBreakpoints } from 'common/hooks';
import {
  inputField,
  counterBody,
  counterBodyError,
  decrementButton,
  incrementButton,
  button,
} from './qty-counter.styles';
import { useTranslation } from 'react-i18next';

type Props = {
  min?: number;
  max?: number;
  onChange?: (c: number) => void;
  onRemove?: () => void;
  step?: number;
  overriddenValue?: number;
  invalid?: boolean;
  className?: string;
  uom?: string;
};

export const CART_QTY_PLUS_BTN_QA = 'button-plus';
export const CART_QTY_MINIS_BTN_QA = 'button-minus';

export const QtyCounter: React.FC<Props> = ({
  min = 1,
  max = 999,
  step = 1,
  onChange,
  onRemove,
  overriddenValue,
  invalid = false,
  className,
  uom = '',
}: Props) => {
  const { t } = useTranslation();
  const { smMobile } = useBreakpoints() ?? {};
  const inputRef = useRef<HTMLInputElement>(null);

  const uomLabel = smMobile ? '' : ` ${uom}`;

  const [count, setCount] = useState<number>(overriddenValue || min);
  const [inputValue, setInputValue] = useState<string | number>(overriddenValue || min);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const applyValue = useCallback(
    (value: number) => {
      const normalizedValue = parseFloat(value.toFixed(2));
      setInputValue(normalizedValue);
      setCount(normalizedValue);
      if (count !== value) {
        onChange?.(normalizedValue);
        if (normalizedValue < min) {
          onRemove?.();
        }
      }
    },
    [count, min, onChange, onRemove],
  );

  const decrement = useCallback(() => {
    applyValue(count - step);
  }, [applyValue, count, step]);

  const increment = useCallback(() => {
    applyValue(count + step <= max ? count + step : max);
  }, [applyValue, count, max, step]);
  const handleInput = () => {
    const countNumber = step * Math.ceil(parseFloat(inputValue as string) / step);
    const value = countNumber > max ? max : countNumber || 0;
    applyValue(value);

    setIsFocused(false);
  };
  const handleKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    if (key === 'Enter' || key === 'Escape') {
      (target as HTMLInputElement).blur();
    }
    if (key === 'ArrowUp') {
      increment();
    }
    if (key === 'ArrowDown') {
      decrement();
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
    setTimeout(() => inputRef.current?.select(), 10);
  };
  return (
    <div data-qa="input-container" className={cx(counterBody, className, invalid ? counterBodyError : null)}>
      <IconButton
        data-qa={CART_QTY_MINIS_BTN_QA}
        className={cx(button, decrementButton)}
        onClick={decrement}
        variant="primary"
        label={t('ui.qty-counter.decrease')}
        size="m"
      >
        <MinusIcon />
      </IconButton>
      <input
        data-qa="add-to-basket-input"
        onChange={(e) => setInputValue(e.target.value)}
        className={inputField}
        onFocus={handleFocus}
        onBlur={handleInput}
        onKeyDown={handleKeys}
        min={min}
        max={max}
        step={step}
        inputMode="numeric"
        value={isFocused ? inputValue : `${inputValue}${uomLabel}`}
        ref={inputRef}
      />
      <IconButton
        data-qa={CART_QTY_PLUS_BTN_QA}
        className={cx(button, incrementButton)}
        onClick={increment}
        variant="primary"
        label={t('ui.qty-counter.increase')}
        size="m"
      >
        <PlusIcon />
      </IconButton>
    </div>
  );
};
