import { useNumberFormatter } from 'common/hooks';
import { useMemo } from 'react';
import type { ProductPriceToDisplayProps } from './types';
import { getCurrency, getFractionPart, getIntegerPart } from 'utils/currency';

export const useProductPrice = ({
  price,
  productPriceCategory,
  hasOldPrice,
}: ProductPriceToDisplayProps) => {
  const currencyFormatter = useNumberFormatter({
    options: {
      currency: price.currency,
      currencyDisplay: 'narrowSymbol',
      style: 'currency',
    },
  });

  const formattedOldPrice = useMemo(
    () => currencyFormatter.format(price.previous_price || 0),
    [currencyFormatter, price.previous_price],
  );

  const finalCurrency = useMemo(() => {
    return getCurrency(currencyFormatter);
  }, [currencyFormatter]);

  const { finalPriceFirst, finalPriceSecond } = useMemo(() => {
    const formatParts = currencyFormatter.formatToParts(price.main_price);
    const fractionPart = getFractionPart(formatParts);

    return {
      finalPriceFirst: getIntegerPart(formatParts),
      // проверка на нулевую дробную часть - '00'
      // при переходе на новую версию nodejs можно будет изящнее поступить
      // просто добавить настройку форматтера
      // trailingZeroDisplay: 'stripIfInteger'
      // в 16 ноде она не работает ¯\_(ツ)_/¯
      // https://tracker.yandex.ru/TODO-176
      finalPriceSecond: parseInt(fractionPart) ? fractionPart : null,
    };
  }, [currencyFormatter, price.main_price]);

  const hasBestPrice = productPriceCategory === 'BPR';
  const hasSpecialPrice = hasOldPrice || hasBestPrice;

  return {
    formattedOldPrice,
    finalCurrency,
    finalPriceFirst,
    finalPriceSecond,
    hasSpecialPrice,
    hasBestPrice,
  };
};
