import Cookies from 'universal-cookie';

export const getLayers = (cookieString = '') => {
  const cookies = new Cookies(cookieString);

  const basketCookies = cookies.get('cartInfo');
  const basket = basketCookies?.split(';').map((item: string) => item.slice(0, item.indexOf(':'))) ?? [];
  const basketWithQuantity =
    basketCookies?.split(';').map((item: string) => {
      const idx = item.indexOf(':');
      return {
        code: item.slice(0, idx),
        quantity: +item.slice(idx + 1, item.length),
      };
    }) ?? [];

  const comparisonCookies = cookies.get('comparison');
  const comparison = comparisonCookies?.split(',') ?? [];

  const shoplistCookies = cookies.get('shoppingList');
  const shoppingList =
    shoplistCookies?.split(';').map((item: string) => item.slice(0, item.indexOf(':'))) ?? [];

  const layersObj = {
    basket,
    basketWithQuantity,
    comparison,
    shoppingList,
  };

  return layersObj;
};
