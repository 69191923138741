import React, { useState } from 'react';
import { RegularButton, type RegularButtonSize } from 'fronton-react';
import { useTranslation } from 'react-i18next';

export const ADD_TO_CART_BTN_QA = 'product-cart-button';

export const ProductCartButton: React.FC<{
  className?: string;
  isActive: boolean;
  size?: RegularButtonSize;
  onClick: () => void;
}> = ({ className, size = 'm', isActive, onClick }) => {
  const { t } = useTranslation();
  const [hoveredOnce, setHoveredOnce] = useState(false);

  const onMouseLeave = () => {
    if (isActive && !hoveredOnce) setHoveredOnce(true);
  };

  return (
    <RegularButton
      data-qa={ADD_TO_CART_BTN_QA}
      className={className}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      aria-label={t('ui.aria-label.add-to-cart')}
      variant="primary"
      size={size}
      wide
    >
      {t('ui.button.add-to-cart')}
    </RegularButton>
  );
};
