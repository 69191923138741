import { css } from 'linaria';
import { styled } from 'linaria/react';
import { ElementType } from 'react';
import { whichBrowser } from 'utils/browsers';

export const rootWrapperStyles = css`
  position: relative;
  width: 100%;
`;

export const RootContainer: ElementType = styled.div<{ inScrollNow: boolean }>`
  display: flex;
  padding: var(--space-125) 0;
  overflow-x: scroll;
  scroll-snap-type: ${({ inScrollNow }) =>
    inScrollNow && whichBrowser() !== 'safari' ? 'none' : 'x proximity'};
  scroll-padding: var(--carousel-scroll-padding, 250px);
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    position: relative;
    min-width: 1px;
    margin-left: calc(var(--space-100) + 1px);
  }

  @media (--to-desktop) {
    width: 100vw;
    padding: var(--space-125) calc(-1 * var(--layout-ears));
    margin-right: var(--layout-ears);
    margin-left: var(--layout-ears);
    scroll-padding: auto;
    scroll-snap-type: none;

    &::after {
      margin-left: calc(-1 * var(--layout-ears));
    }
  }
`;
