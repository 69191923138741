import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

export const getCookies = ({ cookies }: RootState) => cookies?.cookies;
export const getCookiesString = createSelector(getCookies, (cookies) =>
  Object.entries(cookies)
    .map((cookie) => cookie.join('='))
    .join('; '),
);

export const getCustomerID = createSelector(getCookies, (cookies) => cookies?.customerId ?? cookies?._ga);

export const isAllCheck = (...args: boolean[]): boolean => args.every((c) => c);

export const getApiOption = createSelector(getCookies, (cookies) => cookies?.['x-api-option']);

export const getIsAuthorized = createSelector(getCookies, (cookie) => {
  return cookie['user-auth'] === 'true';
});

export const getLang = createSelector(getCookies, (cookies) => {
  const validLangs = ['ru', 'kk'];
  const cookieLang = cookies?.lang?.toLowerCase() ?? 'ru';

  return validLangs.includes(cookieLang) ? cookieLang : 'ru';
});
