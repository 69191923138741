import { useMemo } from 'react';

export type Options = {
  locales?: string | string[];
  options?: Intl.NumberFormatOptions;
};

export const useNumberFormatter = ({ locales, options }: Options = {}) => {
  return useMemo(() => new Intl.NumberFormat(locales ?? 'ru-RU', options), [locales, options]);
};
