const INTEGER_PART = 'integer';
const FRACTION_PART = 'fraction';
const DECIMAL_PART = 'decimal';
const GROUP_PART = 'group';

const partReducer = (formatParts: Intl.NumberFormatPart[], parts: Intl.NumberFormatPartTypes[]) => {
  return formatParts
    .reduce((acc, { type, value }) => {
      if (parts.includes(type)) {
        acc += value;
      }
      return acc;
    }, '')
    .trim();
};

export const getCurrency = (format: Intl.NumberFormat) => {
  try {
    return format?.formatToParts(1)?.find(({ type }) => type === 'currency')?.value ?? '₽';
  } catch (e) {
    return '₽';
  }
};

export const getNumericPart = (formatParts: Intl.NumberFormatPart[]) => {
  return partReducer(formatParts, [INTEGER_PART, DECIMAL_PART, FRACTION_PART, GROUP_PART]);
};

export const getIntegerPart = (formatParts: Intl.NumberFormatPart[]) => {
  return partReducer(formatParts, [INTEGER_PART, GROUP_PART]);
};

export const getFractionPart = (formatParts: Intl.NumberFormatPart[]) => {
  return partReducer(formatParts, [FRACTION_PART, GROUP_PART]);
};
