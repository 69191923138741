import { css } from 'linaria';
import React, { useEffect, useRef, useState } from 'react';
import { memo } from 'common/hocs';
import { useVisualViewportApi } from 'common/hooks';
import { Product, ProductView } from './types';
import { ImgWrapper } from './styles';
import { SkeletonItem } from '../skeleton';

type ProductImageProps = {
  mediaMainPhoto: Product['mediaMainPhoto'];
  alt: string;
  view: ProductView;
  withoutSchema?: boolean;
};

export const ProductImage: React.FC<ProductImageProps> = memo(
  ({ mediaMainPhoto, alt, view, withoutSchema = false }) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [scaled, setScaled] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [toShowSkeleton, setToShowSkeleton] = useState(false);

    useVisualViewportApi((viewport) => viewport.scale > 2 && setScaled(true), { handle: 'resize' });

    const handleOnLoad = () => setLoaded(true);

    useEffect(() => {
      setToShowSkeleton(true);
      if (imgRef.current?.complete) {
        handleOnLoad();
      }
    }, []);
    const upscaleQuality = (
      url: string | undefined,
      x2 = false,
      size: 'desktop' | 'tablet' | '320' | '360' | '375' | '420' = 'desktop',
    ) => {
      if (url) {
        const sizes = {
          'large-card': {
            '320': '_128',
            '360': '_148',
            '375': '_156',
            '420': '_173',
            tablet: '_180',
            desktop: '_240',
          },
          list: {
            '320': '_128',
            '360': '_148',
            '375': '_156',
            '420': '_173',
            tablet: '_160',
            desktop: '_160',
          },
        };

        const drp = {
          scaled: {
            x: '2.0',
            xx: '4.0',
          },
          standart: {
            x: '1.0',
            xx: '2.0',
          },
        };

        let result = url.replace(
          /upload\//,
          `upload/dpr_${drp[scaled ? 'scaled' : 'standart'][x2 ? 'xx' : 'x']},`,
        );
        return result.replace(/(_150|_180)(?=.*v\d{10})/g, sizes[view][size]);
      }

      return null;
    };

    if (!mediaMainPhoto) return null;

    const photos = {
      desktop: {
        x: upscaleQuality(mediaMainPhoto.desktop, false, 'desktop'),
        xx: upscaleQuality(mediaMainPhoto.desktop, true, 'desktop'),
      },
      tablet: {
        x: upscaleQuality(mediaMainPhoto.tablet, false, 'tablet'),
        xx: upscaleQuality(mediaMainPhoto.tablet, true, 'tablet'),
      },
      420: {
        x: upscaleQuality(mediaMainPhoto.mobile, false, '420'),
        xx: upscaleQuality(mediaMainPhoto.mobile, true, '420'),
      },
      375: {
        x: upscaleQuality(mediaMainPhoto.mobile, false, '375'),
        xx: upscaleQuality(mediaMainPhoto.mobile, true, '375'),
      },
      360: {
        x: upscaleQuality(mediaMainPhoto.mobile, false, '360'),
        xx: upscaleQuality(mediaMainPhoto.mobile, true, '360'),
      },
      320: {
        x: upscaleQuality(mediaMainPhoto.mobile, false, '320'),
        xx: upscaleQuality(mediaMainPhoto.mobile, true, '320'),
      },
    };

    const schemaMarkup: {
      itemProp?: string;
    } = {};
    if (!withoutSchema) {
      schemaMarkup.itemProp = 'image';
    }

    return (
      <ImgWrapper>
        {toShowSkeleton && !loaded && <SkeletonItem blink={true} position="absolute" />}
        <picture className={productPicture}>
          <source media="(min-width: 1200px)" srcSet={`${photos.desktop.x} 1x, ${photos.desktop.xx} 2x`} />

          <source media="(min-width: 768px)" srcSet={`${photos.tablet.x} 1x, ${photos.tablet.xx} 2x`} />

          <source media="(min-width: 420px)" srcSet={`${photos['420'].x} 1x, ${photos['420'].xx} 2x`} />

          <source media="(min-width: 375px)" srcSet={`${photos['375'].x} 1x, ${photos['375'].xx} 2x`} />

          <source media="(min-width: 360px)" srcSet={`${photos['360'].x} 1x, ${photos['360'].xx} 2x`} />

          <img
            ref={imgRef}
            className={productImage}
            width={200}
            height={200}
            alt={alt}
            src={photos['320'].x ?? undefined}
            srcSet={`${photos['320'].x} 1x, ${photos['320'].xx} 2x`}
            {...schemaMarkup}
            loading="lazy"
            onLoad={handleOnLoad}
          />
        </picture>
      </ImgWrapper>
    );
  },
);

const productPicture = css`
  display: block;
  width: 100%;
  height: auto;
  line-height: 0;
`;

const productImage = css`
  width: 100%;
  height: auto;
  object-fit: scale-down;
  -webkit-user-drag: none;
`;
