import { Http, HttpRequestConfig, HttpInstance, HttpCancelToken } from '@okapi/http';

export type ApiRequestConfig = HttpRequestConfig;
export type ApiCancelToken = HttpCancelToken;

export class Api {
  http: HttpInstance;

  constructor() {
    this.http = new Http({
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  }

  public abortController = () => this.http.abortController();

  post = <Response = unknown, Data extends Record<string, string> = Record<string, string>>(
    url: string,
    data: Data,
    config?: ApiRequestConfig,
  ) =>
    this._doFetch<Response>(url, {
      method: 'POST',
      data,
      headers: config?.headers,
      ...config,
    });

  put = <Response = unknown, Data = object>(url: string, data: Data, config?: ApiRequestConfig) =>
    this._doFetch<Response>(url, {
      method: 'PUT',
      data,
      headers: config?.headers,
      ...config,
    });

  get = <Response = unknown, Data extends Record<string, string> = Record<string, string>>(
    url: string,
    data: Data,
    config?: ApiRequestConfig,
  ) => {
    const params = new URLSearchParams(data).toString();
    const resultUrl = `${url}?${params}`;

    return this._doFetch<Response>(resultUrl, { method: 'GET', ...config });
  };

  private _doFetch<Response = unknown>(url: string, init: ApiRequestConfig) {
    const resultUrl = /^\/\//.test(url as string) ? `https:${url}` : url;
    return this.http
      .request<Response>(resultUrl, init)
      .then((response) => (response.status === 200 ? response.data : Promise.reject(response)))
      .catch((e) => Promise.reject(e.response));
  }
}

export const api = new Api();
