import React from 'react';
import { type ColorValueStrict, Label } from 'fronton-react';
import { memo } from 'common/hocs/memo';

type ProductLabelProps = { labelType: 'PIM' | 'BPR' | 'LOF' | 'STD' | 'WIZ'; className?: string };

const labelTypes = {
  LOF: {
    name: 'Выгодная покупка',
    textColor: 'text-invert',
    bgColor: 'attention-primary',
  },
  WIZ: {
    name: 'Онлайн-примерка',
    textColor: undefined,
    bgColor: 'secondary-green-light',
  },
};

export const ProductLabel = memo<ProductLabelProps>(function ProductLabel({ labelType, className }) {
  if (labelType === 'STD' || labelType === 'PIM' || labelType === 'BPR') return null;

  const labelObj = labelTypes[labelType];

  return labelObj ? (
    <Label
      className={className}
      background={labelObj.bgColor as ColorValueStrict}
      color={labelObj.textColor as ColorValueStrict}
      data-qa="product-tag"
    >
      {labelObj.name}
    </Label>
  ) : null;
});
