export function range(end: number, start?: number, step?: number) {
  if (start === undefined) {
    start = 0;
  }

  // eslint-disable-next-line no-nested-ternary
  step = step === undefined ? (start < end ? 1 : -1) : step;

  let index = -1;
  let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);
  const result = new Array(length);

  while (length) {
    length -= 1;
    index += 1;
    result[index] = start;
    start += step;
  }

  return result;
}
