import React, { ReactNode } from 'react';

type WithAdaptiveOptions = {
  displayName?: string;
  ssrClassName?: string;
  children?: HTMLElement | ReactNode;
};

export const createAdaptiveComponent = ({ displayName, ssrClassName }: WithAdaptiveOptions) => {
  const AdaptiveComponent: React.FC<{ children: ReactNode }> = ({ children }) => (
    <div className={ssrClassName}>{children}</div>
  );

  if (process.env.NODE_ENV !== 'production') {
    AdaptiveComponent.displayName = displayName;
  }

  return AdaptiveComponent;
};
