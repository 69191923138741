import { AtMobile } from 'common/components';
import React from 'react';
import { bottomLineStyles } from './styles';
import { RegularButton } from 'fronton-react';
import { ProductsBsCarousel } from './products-bs-carousel';
import type { ProductsCarouselComponentProps } from './types';
import type { ProductType } from 'features/products/types';
import { useTranslation } from 'react-i18next';

type ProductsCarouselBottomLineProps = ProductsCarouselComponentProps & {
  products: ProductType[];
};

export const ProductsCarouselBottomLine = ({
  products,
  slWidgetId,
  ratings,
  elementBlock,
  title,
}: ProductsCarouselBottomLineProps) => {
  const [showBs, setShowBs] = React.useState<boolean>(false);
  const showModalCb = React.useCallback(() => {
    setShowBs(true);
  }, [setShowBs]);

  const { t } = useTranslation();
  const seeAllButtonCaption = t('ui.button.see-all');

  const closeModalCb = React.useCallback(() => setShowBs(false), [setShowBs]);

  return (
    <AtMobile>
      <div className={bottomLineStyles}>
        <RegularButton size="l" data-qa="show-all-pdp" variant="secondary" wide onClick={showModalCb}>
          {seeAllButtonCaption}
        </RegularButton>
      </div>
      <ProductsBsCarousel
        products={products}
        slWidgetId={slWidgetId}
        ratings={ratings}
        elementBlock={elementBlock as string}
        show={showBs}
        onCloseIntent={closeModalCb}
        categoryTitle={title}
      />
    </AtMobile>
  );
};
