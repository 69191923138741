/* eslint-disable max-lines */

import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { cx } from 'linaria';
import { Button, Layout, LinkButton, Rating, Typography } from 'fronton-react';
import { cn } from 'utils/classnames';
import { getPathname } from 'common/selectors';
import { Carousel, FromTablet, AtMobile } from 'common/components';
import { analyticsUtils } from 'utils/analytics-utils';
import { patchUrlByLocale } from 'utils';
import { memo } from 'common/hocs';
import { getProductsByIdsData, getRatingsData } from '../../ducks/selectors';
import { ProductsCarouselItem } from './products-carousel-item';
import {
  pageContentStyles,
  carouselWrapperStyles,
  topLineStyles,
  carouselStyles,
  carouselItemStyles,
  seeMoreCardStyles,
  controlStyles,
  topLineSeeMoreStyles,
  topLineItemStyles,
  flatListStyles,
  bottomLineStyles,
  bottomSeeMoreStyles,
  seeMoreCarouselItem,
  seeMoreCaption,
  helperRating,
  titleStyle,
  productCardStyles,
  mobileItem,
  mobileItem3p,
  mobileItemCommon,
} from './styles';
import { ProductsCarouselControl } from './products-carousel-control';
import { ProductsCarouselComponentProps } from './types';
import { ChevronRightIcon } from '@fronton/icons-react';
import { ProductType } from 'common/components/product-card/types';
import { getRRContentId } from 'features/get-rr-content-id';
import { useTranslation } from 'react-i18next';
import { Product } from '../product';
import { isOnlineOnly } from '../product/utils';
import { ProductsCarouselBottomLine } from './products-carousel-bottom-line';

export const ProductsCarousel = memo<ProductsCarouselComponentProps>(
  ({
    isFromPdp = false,
    className,
    carouselClassName,
    innerContainerClassName,
    flatListClassName,
    titleClassName,
    title,
    titleAs = 'h2',
    showSeeMore = true,
    seeMoreLink = '/',
    slWidgetId,
    elementBlock = 'not set',
    rrTracking = [],
    'data-qa': dataQA,
  }) => {
    const pathname = useSelector(getPathname);
    const products: ProductType[] | null = useSelector(getProductsByIdsData);
    const ratings = useSelector(getRatingsData);

    seeMoreLink = patchUrlByLocale(seeMoreLink, pathname);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const { t } = useTranslation();
    const seeAllButtonCaption = t('ui.button.see-all');

    const seeMoreLinkOnClickHandler = () => {
      analyticsUtils.sendEvent('GA:clickButton', {
        elementBlock,
        elementType: 'button',
        elementName: title,
        elementText: seeAllButtonCaption,
        elementLink: seeMoreLink,
      });
    };
    const topBarVisible = title || showSeeMore;
    const limitedProducts = useMemo(() => (products ? products.slice(0, 4) : null), [products]);

    if (!products || !products.length) return null;

    return (
      <Layout className={cn(pageContentStyles, className)}>
        <Rating className={helperRating} value={0} />
        <div className={carouselWrapperStyles} data-qa={dataQA}>
          {topBarVisible && (
            <div className={topLineStyles}>
              {title && (
                <div className={topLineItemStyles}>
                  {title && (
                    <Typography
                      className={cn(isFromPdp ? titleStyle : '', titleClassName)}
                      variant={titleAs}
                      data-qa="products-carousel-title"
                    >
                      {title}
                    </Typography>
                  )}
                </div>
              )}

              {canScrollRight && showSeeMore && (
                <FromTablet>
                  <div className={topLineItemStyles}>
                    <LinkButton
                      iconRight={<ChevronRightIcon />}
                      variant="normal"
                      size="s"
                      href={seeMoreLink}
                      onClick={seeMoreLinkOnClickHandler}
                      withExtraClickZone
                      className={topLineSeeMoreStyles}
                      data-qa="products-carousel-seemore-link"
                    >
                      {seeAllButtonCaption}
                    </LinkButton>
                  </div>
                </FromTablet>
              )}
            </div>
          )}

          {/* Flat list for mobile version */}
          {limitedProducts && (
            <AtMobile>
              <div
                className={cn(flatListStyles, flatListClassName)}
                data-sl-widget-id={slWidgetId}
                data-sl-external="true"
                data-qa="products-carousel-flatlist"
              >
                {limitedProducts.map((product, index) => (
                  <Product
                    key={product.productId + index}
                    productPosition={index + 1}
                    className={cx(
                      productCardStyles,
                      mobileItem,
                      isOnlineOnly(product.source) ? mobileItem3p : mobileItemCommon,
                    )}
                    product={product}
                    reviews={ratings}
                    elementBlock={elementBlock}
                    rrContentId={getRRContentId({ id: product.productId, rrTracking })}
                    inWidget
                  />
                ))}
              </div>
            </AtMobile>
          )}
          <FromTablet>
            <Carousel
              className={cn(carouselStyles, carouselClassName)}
              innerContainerClassName={cn(innerContainerClassName)}
              canScrollCallBack={(_, right) => setCanScrollRight(right ?? false)}
              controlLeft={<ProductsCarouselControl direction="left" />}
              controlRight={<ProductsCarouselControl direction="right" />}
              data-qa="products-carousel"
              slWidgetId={slWidgetId}
            >
              {products.map((product, index) => (
                <ProductsCarouselItem
                  key={product.productId + index}
                  className={carouselItemStyles}
                  index={index + 1}
                  product={product}
                  ratings={ratings}
                  elementBlock={elementBlock}
                  data-sl-product-slot={index}
                  rrContentId={getRRContentId({ id: product.productId, rrTracking })}
                />
              ))}

              {/* "Смотреть всё" card with link at the end of the carousel list */}
              {showSeeMore && !!products.length && (
                <div
                  className={cn(carouselItemStyles, seeMoreCarouselItem)}
                  data-qa="products-carousel-item-seemore"
                >
                  <a href={seeMoreLink} onClick={seeMoreLinkOnClickHandler} className={seeMoreCardStyles}>
                    <Button
                      className={controlStyles}
                      aria-label={t('ui.aria-label.see-all')}
                      iconOnly
                      kind="regular"
                      rounded
                      invert
                      size="l"
                      variant="primary"
                    >
                      <ChevronRightIcon type="outline" size="l" color="text-primary" />
                    </Button>
                    <Typography
                      variant="m"
                      size="body_short"
                      color="text-primary"
                      className={seeMoreCaption}
                    >
                      {seeAllButtonCaption}
                    </Typography>
                  </a>
                </div>
              )}
            </Carousel>
          </FromTablet>

          {/* Bottom line */}
          {!isFromPdp && showSeeMore && (
            <AtMobile>
              <div className={bottomLineStyles}>
                <LinkButton
                  variant="normal"
                  size="m"
                  href={seeMoreLink}
                  onClick={seeMoreLinkOnClickHandler}
                  withExtraClickZone
                  className={bottomSeeMoreStyles}
                  data-qa="products-carousel-seemore-link"
                >
                  <span>{seeAllButtonCaption}</span>
                  <ChevronRightIcon type="outline" size="l" color="text-minor" />
                </LinkButton>
              </div>
            </AtMobile>
          )}

          {/* Bottom line */}
          {isFromPdp && (
            <ProductsCarouselBottomLine
              products={products}
              slWidgetId={slWidgetId}
              ratings={ratings}
              elementBlock={elementBlock}
              title={title}
            />
          )}
        </div>
      </Layout>
    );
  },
);
