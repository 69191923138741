import { combineReducers } from 'redux';
import { productsActions, products, productsRootSaga } from './products';
import { basket, basketRootSaga } from './basket';
import { BASKET_RESET, BASKET_SET, BASKET_UPDATE_ITEM } from './basket/ducks/actions';

export const featuresReducer = combineReducers({
  products,
  basket,
});

export const featuresActions = {
  productsActions,
  basketActions: { BASKET_SET, BASKET_UPDATE_ITEM, BASKET_RESET },
};

export function featuresSagas() {
  return [basketRootSaga(), productsRootSaga()];
}
