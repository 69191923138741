import React from 'react';
import { mobileItem, productCardStyles } from './styles';
import { ProductsCarouselItemProps } from './types';
import { Product } from '../product';
import { cx } from 'linaria';

export const ProductsCarouselItem: React.FC<ProductsCarouselItemProps> = ({
  className,
  index = 1,
  product,
  ratings = [],
  elementBlock,
  'data-qa': dataQA = 'products-carousel-item',
  'data-sl-product-slot': dataSlProductSlot = 0,
  rrContentId = '',
}) => (
  <div
    className={cx(className, mobileItem)}
    data-sl-product-id={product.productId}
    data-sl-strategy-id="255"
    data-sl-product-slot={dataSlProductSlot}
    data-qa={dataQA}
  >
    <Product
      key={product.productId}
      productPosition={index}
      className={productCardStyles}
      type="large-card"
      product={product}
      reviews={ratings}
      elementBlock={elementBlock}
      inWidget
      rrContentId={rrContentId}
    />
  </div>
);
