import { css } from 'linaria';
import { createAdaptiveComponent } from './create-adaptive-component';

const atMobile = css`
  display: contents;

  @media (--from-tablet) {
    display: none;
  }
`;

export const AtMobile = createAdaptiveComponent({
  displayName: 'AtMobile',
  ssrClassName: atMobile,
});
