import { clamp } from '../clamp';
import { ActiveAnimations } from './activate-animations';
import { easing, Easing } from './easing';

export type Coords = { x: number; y: number };

export type Options = {
  cancelOnUserAction?: boolean;
  easingFn?: Easing;
  elementToScroll: HTMLElement;
  horizontalOffset?: number;
  maxDuration?: number;
  minDuration?: number;
  passive?: boolean;
  speed?: number;
  verticalOffset?: number;
};

export const scrollToPosition = (
  { x = 0, y = 0 }: Coords,
  {
    cancelOnUserAction = true,
    easingFn = 'easeInOutCubic',
    elementToScroll,
    horizontalOffset = 0,
    maxDuration = 3000,
    minDuration = 250,
    speed = 500,
    verticalOffset = 0,
  }: Options,
) => {
  const activeAnimations = new ActiveAnimations();

  let xState = x;
  let yState = y;

  // Add offsets
  xState += horizontalOffset;
  yState += verticalOffset;

  const scrollTo = (xPosition: number, yPosition: number) => {
    elementToScroll.scrollLeft = xPosition;
    elementToScroll.scrollTop = yPosition;
  };

  // Horizontal scroll distance
  const maxHorizontalScroll = elementToScroll.scrollWidth - elementToScroll.clientWidth;
  const initialHorizontalScroll = elementToScroll.scrollLeft;

  // If user specified scroll position is greater than maximum available scroll
  if (xState > maxHorizontalScroll) {
    xState = maxHorizontalScroll;
  }

  // Calculate distance to scroll
  const horizontalDistanceToScroll = xState - initialHorizontalScroll;

  // Vertical scroll distance distance
  const maxVerticalScroll = elementToScroll.scrollHeight - elementToScroll.clientHeight;
  const initialVerticalScroll = elementToScroll.scrollTop;

  // If user specified scroll position is greater than maximum available scroll
  if (yState > maxVerticalScroll) {
    yState = maxVerticalScroll;
  }

  // Calculate distance to scroll
  const verticalDistanceToScroll = yState - initialVerticalScroll;

  // Calculate duration of the scroll
  const horizontalDuration = Math.abs(Math.round((horizontalDistanceToScroll / 1000) * speed));
  const verticalDuration = Math.abs(Math.round((verticalDistanceToScroll / 1000) * speed));

  let duration = horizontalDuration > verticalDuration ? horizontalDuration : verticalDuration;

  // Set minimum and maximum duration
  if (duration < minDuration) {
    duration = minDuration;
  } else if (duration > maxDuration) {
    duration = maxDuration;
  }

  return new Promise((resolve: (hasScrolledToPosition: boolean) => void) => {
    // Scroll is already in place, nothing to do
    if (horizontalDistanceToScroll === 0 && verticalDistanceToScroll === 0) {
      // Resolve promise with a boolean hasScrolledToPosition set to true
      resolve(true);
    }

    // Cancel existing animation if it is already running on the same element
    activeAnimations.remove(elementToScroll, true);

    // To cancel animation we have to store request animation frame ID
    let requestID: number;

    // Cancel animation handler
    const cancelAnimation = () => {
      removeListeners();
      cancelAnimationFrame(requestID);

      // Resolve promise with a boolean hasScrolledToPosition set to false
      resolve(false);
    };

    // Registering animation so it can be canceled if function
    // gets called again on the same element
    activeAnimations.add(elementToScroll, cancelAnimation);

    // Prevent user actions handler
    const preventDefaultHandler = (e: Event) => e.preventDefault();

    const handler = cancelOnUserAction ? cancelAnimation : preventDefaultHandler;

    // If animation is not cancelable by the user, we can't use passive events
    const eventOptions = { passive: cancelOnUserAction };

    const events = ['wheel', 'touchstart', 'keydown', 'mousedown'];

    // Function to remove listeners after animation is finished
    const removeListeners = () => {
      events.forEach((eventName) => {
        elementToScroll.removeEventListener(eventName, handler);
      });
    };

    // Add listeners
    events.forEach((eventName) => {
      elementToScroll.addEventListener(eventName, handler, eventOptions);
    });

    // Animation
    const startingTime = performance.now();

    const step = (time: number) => {
      const currentDuration = time - startingTime;
      const timeFraction = clamp(0, 1, currentDuration / duration);
      const drawFunc = easing[easingFn];

      const horizontalScrollPosition = Math.round(
        initialHorizontalScroll + horizontalDistanceToScroll * drawFunc(timeFraction),
      );
      const verticalScrollPosition = Math.round(
        initialVerticalScroll + verticalDistanceToScroll * drawFunc(timeFraction),
      );

      if (currentDuration < duration && (horizontalScrollPosition !== x || verticalScrollPosition !== y)) {
        // If scroll didn't reach desired position or time is not elapsed
        // Scroll to a new position
        scrollTo(horizontalScrollPosition, verticalScrollPosition);

        // And request a new step
        requestID = requestAnimationFrame(step);
      } else {
        // If the time elapsed or we reached the desired offset
        // Set scroll to the desired offset (when rounding made it to be off a pixel or two)
        // Clear animation frame to be sure
        scrollTo(x, y);

        cancelAnimationFrame(requestID);

        // Remove listeners
        removeListeners();

        // Remove animation from the active animations coordinator
        activeAnimations.remove(elementToScroll, false);

        // Resolve promise with a boolean hasScrolledToPosition set to true
        resolve(true);
      }
    };

    // Start animating scroll
    requestID = requestAnimationFrame(step);
  });
};
