import { BasketItem } from '../types';

/**
 * Получение количества единиц определенного товара в корзине
 * @param {BasketItem[]} currentItems - массив товаров
 * @param {string} productCode - код товара
 * @returns {number} - количество единиц товара
 */
export const getProductQuantity = (currentItems: BasketItem[], productCode: string): number => {
  const item = currentItems.find(({ code }) => code === productCode);

  if (item) {
    return item.quantity;
  }

  return 0;
};
