import { createAction } from 'typesafe-actions';
import { BasketItem } from '../types';

/**
 * Do not use `BASKET_` actions outside of `feature` dir.
 * Use BasketFlowActions with GA handler included
 */
export const BASKET_RESET = createAction('basket/BASKET_RESET')();
export const BASKET_UPDATE_ITEM = createAction('basket/BASKET_UPDATE_ITEM')<BasketItem>();
export const BASKET_SET = createAction('basket/BASKET_SET')<BasketItem[]>();

/**
 * Actions for usage in other features
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BasketFlowActions {
  /**
   * @description Prefix can be used for intercepting all events from namespace,
   * e.g. action.type.startsWith(BasketFlowActions.PREFIX)
   */
  export const PREFIX = 'basket/flow';

  export type UpdateItemPayload = BasketItem & { isNeedGA?: boolean };
  export const UPDATE_ITEM = createAction(`${PREFIX}/UPDATE_ITEM`)<UpdateItemPayload>();

  export type ResetItemPayload = Omit<BasketItem, 'quantity'>;
  export const RESET_ITEM = createAction(`${PREFIX}/RESET_ITEM`)<ResetItemPayload>();

  export type SyncItemWithCookiePayload = Omit<BasketItem, 'quantity'> & { isNeedGA?: boolean };
  export const SYNC_ITEM_WITH_COOKIE = createAction(
    `${PREFIX}/SYNC_ITEM_WITH_COOKIE`,
  )<SyncItemWithCookiePayload>();
  export const SYNC_CART_WITH_COOKIE = createAction(`${PREFIX}/SYNC_CART_WITH_COOKIE`)();
}
