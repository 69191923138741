import { applyMiddleware, legacy_createStore as createReduxStore } from 'redux';
import { middleware as createRouterMiddleware } from '@okapi/router-connect';
import { createMiddleware as createCookieMiddleware } from 'isomorphic-redux-cookie-middleware';
import { GlobalRouter } from '@okapi/router-react';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { END } from 'redux-saga';
import { RootState } from 'typesafe-actions';
import { createMiddleware as createHeaderMiddleware } from './header-middleware/middleware';
import { Layers } from './header-middleware/types';
import { Env, rootReducer } from './root-reducer';
import { rootSaga } from './root-saga';

export type CreateStoreParams = {
  router: GlobalRouter;
  host: string;
  preloadedState: RootState;
  cookie: string;
  layers: Layers;
  isBot: boolean;
  userAgent: string;
  env: Env;
  uuid?: string;
};

export function createStore({
  router,
  host,
  preloadedState,
  cookie,
  layers,
  isBot,
  userAgent,
  env,
  uuid,
}: CreateStoreParams) {
  const sagaMiddleware = createSagaMiddleware();
  const cookieMiddleware = createCookieMiddleware();
  const routerMiddleware = createRouterMiddleware(router);
  const headerMiddleware = createHeaderMiddleware();
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

  const store = createReduxStore(
    rootReducer(host, router, cookie, layers, isBot, userAgent, env, uuid),
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware, cookieMiddleware, headerMiddleware)),
  );

  const saga = sagaMiddleware.run(rootSaga);

  return { store, saga, close: () => store.dispatch(END) };
}
