export const browsers = {
  chrome: 'chrome',
  safari: 'safari',
  firefox: 'firefox',
  unknown: 'unknown',
} as const;

let browser: keyof typeof browsers | null = null;

export function whichBrowser() {
  if (__IS_SERVER__) {
    return browsers.unknown;
  }

  if (!browser) {
    browser = detectBrowser();
  }

  return browser;
}

const detectBrowser = () => {
  const { userAgent } = navigator;

  const isChrome = userAgent.indexOf('Chrome') > -1;
  const isFirefox = userAgent.indexOf('Firefox') > -1;
  const isSafari = userAgent.indexOf('Safari') > -1;

  if (isChrome && isSafari) return browsers.chrome;

  if (isSafari) return browsers.safari;

  if (isFirefox) return browsers.firefox;

  return browsers.unknown;
};
