import React from 'react';

export const BestPriceIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="#21282B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05164 8.44945C7.67428 7.371 8.47883 6.56899 9.24693 6.1301C10.0114 5.6933 10.628 5.66792 11.0314 5.88275C11.0402 5.88845 11.0492 5.89396 11.0584 5.89927L11.1058 5.92664C11.4858 6.17335 11.7646 6.71718 11.7685 7.58593C11.7725 8.47057 11.4802 9.56833 10.8575 10.6468C10.2349 11.7252 9.43034 12.5272 8.66224 12.9661C7.87871 13.4138 7.25046 13.4293 6.84794 13.1969C6.44542 12.9645 6.1447 12.4127 6.14066 11.5103C6.13671 10.6257 6.42899 9.5279 7.05164 8.44945ZM12.9822 7.00998C13.0059 7.19744 13.0176 7.3884 13.0185 7.58033C13.0236 8.72294 12.6535 10.0361 11.9401 11.2718C11.2267 12.5075 10.2745 13.4846 9.28239 14.0515C9.11473 14.1473 8.94239 14.233 8.76713 14.3066L8.94017 14.4065C9.34191 14.6384 9.97021 14.623 10.7542 14.1751C11.5226 13.7361 12.3274 12.9342 12.9498 11.8563C13.5721 10.7784 13.8641 9.68051 13.8601 8.79557C13.856 7.89263 13.5552 7.34078 13.1535 7.10884L12.9822 7.00998ZM6.17807 14.2529C5.26354 13.6977 4.89563 12.6228 4.89068 11.5159C4.88556 10.3733 5.25569 9.06012 5.9691 7.82445C6.68252 6.58879 7.6347 5.61166 8.62678 5.04478C9.60345 4.48671 10.7388 4.2698 11.6862 4.81677C11.7096 4.83028 11.7327 4.84411 11.7554 4.85827L13.7785 6.02631C14.7254 6.57303 15.1049 7.66517 15.1101 8.78987C15.1153 9.93257 14.7455 11.2459 14.0323 12.4813C13.319 13.7167 12.3665 14.6936 11.3743 15.2604C10.3977 15.8183 9.26212 16.0357 8.31517 15.489L6.22013 14.2794C6.20565 14.2711 6.19162 14.2622 6.17807 14.2529Z"
      fill="#FDC300"
    />
  </svg>
);
