import { combineReducers } from 'redux';
import { all, put, takeLatest, cancelled, select } from 'redux-saga/effects';
import { createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { api } from 'api';
import { getRegion } from 'common/selectors';
import { FetchRatingsResponse, Rating } from '../types';
import { fetchRatings } from './api';

export const FETCH_RATINGS = createAsyncAction(
  'FETCH_RATINGS/request',
  'FETCH_RATINGS/success',
  'FETCH_RATINGS/failure',
  'FETCH_RATINGS/cancel',
)<{ productsIds: string[] }, FetchRatingsResponse, boolean, void>();

export const RESET = createAction('FETCH_RATINGS/RESET')();

export const data = createReducer<Rating[] | null>(null)
  .handleAction(FETCH_RATINGS.success, (_, { payload }) => payload.response ?? [])
  .handleAction([FETCH_RATINGS.request, FETCH_RATINGS.failure, RESET], () => null);

export const isFetching = createReducer(false)
  .handleAction(FETCH_RATINGS.request, () => true)
  .handleAction([FETCH_RATINGS.success, FETCH_RATINGS.failure, FETCH_RATINGS.cancel, RESET], () => false);

export const error = createReducer<boolean>(false)
  .handleAction([FETCH_RATINGS.failure], (_, { payload }) => payload)
  .handleAction([FETCH_RATINGS.request, FETCH_RATINGS.success, RESET], () => false);

export const ratings = combineReducers({
  data,
  isFetching,
  error,
});

export function* runRatingsFetch(props: { productsIds: string[] }): Generator {
  const abortController = api.abortController();
  try {
    const regionCode = (yield select(getRegion)) as ReturnType<typeof getRegion>;
    const response = (yield fetchRatings(
      { ...props },
      abortController.token,
      regionCode,
    )) as FetchRatingsResponse;
    yield put(FETCH_RATINGS.success(response));
  } catch {
    yield put(FETCH_RATINGS.failure(true));
  } finally {
    if (yield cancelled()) {
      abortController.cancel();
      yield put(FETCH_RATINGS.cancel());
    }
  }
}

export function* rootSaga(): Generator {
  yield all([
    takeLatest(FETCH_RATINGS.request, ({ payload }) => runRatingsFetch(payload)),
    yield takeLatest(FETCH_RATINGS.success, () => {
      if (typeof window !== 'undefined') {
        window.dispatchEvent(new CustomEvent('resize'));
      }
    }),
  ]);
}
