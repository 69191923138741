import { combineReducers } from 'redux';
import { all, put, select, takeLatest, cancelled } from 'redux-saga/effects';
import { getRegion } from 'common/selectors';
import { createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { api } from 'api';
import { FetchProductsByIdsResponse, FetchProductsResponse, ProductType } from '../types';
import { fetchProductsByIDs } from './api';

export const FETCH_PRODUCTS_BY_IDS = createAsyncAction(
  'FETCH_PRODUCTS_BY_IDS/request',
  'FETCH_PRODUCTS_BY_IDS/success',
  'FETCH_PRODUCTS_BY_IDS/failure',
  'FETCH_PRODUCTS_BY_IDS/cancel',
)<{ productsIds: string[] }, FetchProductsByIdsResponse, boolean, void>();

export const RESET = createAction('FETCH_PRODUCTS_BY_IDS/RESET')();

export const data = createReducer<ProductType[] | null>(null)
  .handleAction(FETCH_PRODUCTS_BY_IDS.success, (_, { payload }) => payload.content ?? [])
  .handleAction([FETCH_PRODUCTS_BY_IDS.request, FETCH_PRODUCTS_BY_IDS.failure, RESET], () => null);

export const isFetching = createReducer(false)
  .handleAction(FETCH_PRODUCTS_BY_IDS.request, () => true)
  .handleAction(
    [FETCH_PRODUCTS_BY_IDS.success, FETCH_PRODUCTS_BY_IDS.failure, FETCH_PRODUCTS_BY_IDS.cancel, RESET],
    () => false,
  );

export const error = createReducer<boolean>(false)
  .handleAction([FETCH_PRODUCTS_BY_IDS.failure], (_, { payload }) => payload)
  .handleAction([FETCH_PRODUCTS_BY_IDS.request, FETCH_PRODUCTS_BY_IDS.success, RESET], () => false);

export const productsByIds = combineReducers({
  data,
  isFetching,
  error,
});

export function* runProductsByIdsRefetch(props: { productsIds: string[] }): Generator {
  const abortController = api.abortController();
  const regionCode = (yield select(getRegion)) as ReturnType<typeof getRegion>;

  try {
    const response = yield fetchProductsByIDs({ regionCode, ...props }, abortController.token);

    yield put(FETCH_PRODUCTS_BY_IDS.success(response as FetchProductsResponse));
  } catch {
    yield put(FETCH_PRODUCTS_BY_IDS.failure(true));
  } finally {
    if (yield cancelled()) {
      abortController.cancel();
      yield put(FETCH_PRODUCTS_BY_IDS.cancel());
    }
  }
}

export function* rootSaga() {
  yield all([takeLatest(FETCH_PRODUCTS_BY_IDS.request, ({ payload }) => runProductsByIdsRefetch(payload))]);
}
