import { css } from 'linaria';

export const counterBody = css`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 112px;
  border-radius: var(--rounding-m);
  box-shadow: inset 0 0 0 1px var(--control-secondary);
  transition: 0.3s box-shadow;
  padding: 0;
  /* stylelint-disable */
  &:hover {
    box-shadow: inset 0 0 0 1px var(--control-hover-secondary);
  }
  /* stylelint-enable */
  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--control-primary);
  }
`;

export const counterBodyError = css`
  box-shadow: inset 0 0 0 1px var(--attention-primary);

  &:hover {
    box-shadow: inset 0 0 0 1px var(--attention-dark);
  }
`;

export const inputField = css`
  min-width: var(--space-400);
  width: 100%;
  height: var(--space-500);
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  caret-color: var(--brand-primary);
  /* stylelint-disable */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    opacity: 1;
    -webkit-appearance: none;
  }
  /* stylelint-enable */
`;

export const button = css`
  height: var(--space-500);
  padding: var(--space-125);
  background: transparent !important;

  --button-hover-color: var(--brand-dark);
`;

export const decrementButton = css`
  border-radius: var(--rounding-m) 0 0 var(--rounding-m);
`;

export const incrementButton = css`
  border-radius: 0 var(--rounding-m) var(--rounding-m) 0;
`;
