const STORAGE_KEY = 'enabledLogAnalytics';

type GAEvent = {
  event: string;
  params: unknown;
};

type GTMLoadingStatusType = 'done' | 'loading';

/**
 * Send events to analytics
 * @class
 * @public
 */

class AnalyticsUtils {
  hasFlagLogAnalytics: string | null = null;

  GTMLoadingStatus: GTMLoadingStatusType = 'loading';

  private eventsQueue: GAEvent[] = [];

  constructor() {
    if (!__IS_SERVER__) {
      this.hasFlagLogAnalytics = localStorage.getItem(STORAGE_KEY);
      if (!this.hasFlagLogAnalytics) {
        localStorage.setItem(STORAGE_KEY, '');
      }

      window.dataLayer = window.dataLayer ?? [];
      if (window.dataLayer.some((eventObj) => eventObj.event === 'gtm.load')) this.GTMLoadingStatus = 'done';

      if (this.GTMLoadingStatus === 'loading') {
        const resolveEvents = () => {
          this.GTMLoadingStatus = 'done';
          this.eventsQueue.forEach(({ event, params }) => this.sendEvent(event, params));
        };

        const originalPush = window.dataLayer.push;
        window.dataLayer.push = function push(...args) {
          if (args[0].event === 'gtm.load') resolveEvents();
          return originalPush.apply(this, args);
        };
      }
    }
  }

  /**
   * Send event with params
   * @param {string} event name of event
   * @param {*} params any data
   * @public
   */

  public sendEvent(event: string, params: unknown) {
    if (this.GTMLoadingStatus === 'done') {
      if (!!this.hasFlagLogAnalytics) {
        // eslint-disable-next-line no-console
        console.log(event, params);
      }

      document.dispatchEvent(new CustomEvent(event, { detail: params }));
    } else {
      this.eventsQueue.push({ event, params });
    }
  }
}

export const analyticsUtils = new AnalyticsUtils();
